import React from "react";
import { Modal } from "reactstrap";
import DataTable from "../../components/DataTable";
import { getCookie } from '../../Utils/helper';
import Api from "../../Utils/API";
import { Api as apiLink } from '../../Utils/consts';
import moment from 'moment';

//Import Image
import LoadingIndicator from "../../components/LoadingIndicator";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import UnderDevelopmentAlert from "../../components/UnderDevelopmentAlert";

let userDetails = getCookie("userDetails");
let loginUser = {};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}

export const Subscriptions = React.memo(props => {  
    const [subscriptions, setSubscriptions] = useState([]); 
    const [state, setState] = useState({
        loading: false,
        newTicket: false,
        editUser: false,
        openModal: false,
        spinner: false,
        customActiveTab: '1',
        selectedFiles: [],
        defaultDate: new Date(),
        list: [],
        details: [],
        company_id: loginUser.company ? loginUser.company.id : '',
        inviteLink: '',
        editUserDetails: '',
        invoice_link: ''
    })

    useEffect(() => {
        let params = {
            company_id: state.company_id,
            is_subscription: 1,
            is_transaction: 0
        }
        Api.companyInvoices(params).then(response => {
            console.log(response.data);
            setSubscriptions(response.data);
        }).catch(err => {
            console.log(err)
        })
    }, [])

    function viewInvoice (e) {
        setState({...state, invoice_link: apiLink + 'invoice/' + e.filename, openModal: true, details: e });
    }

    const dataTable = {
        columns: [
            {
                label: "SUBSCRIPTION",
                field: "name",
                width: 150
            },
            {
                label: "PLAN",
                field: "plan",
                width: 150
            },
            {
                label: "AMOUNT",
                field: "amount"
            },
            {
                label: "DETAIL",
                field: "detail",
                width: 150
            },
            {
                label: "NEXT TRANSACTION DATE",
                field: "next_transaction",
                width: 150
            },
            {
                label: "ACTIONS",
                field: "actions",
                width: 150
            }
        ],
        rows: []
    };

    subscriptions.forEach(subs => {
        dataTable.rows.push(
            {
                name: subs.stripe_plan ? subs.stripe_plan.type : '',
                plan: subs.plan,
                amount: `$${(subs.total/100).toFixed(2)}/month`,
                detail: subs.stripe_plan ? subs.stripe_plan.description : '',
                next_transaction: subs.nextChargeDate ? subs.nextChargeDate : '',
                actions: <div>  <button invoice={subs.filename} onClick={() => { viewInvoice(subs) }} className="btn btn-circle"><i className="fa fa-search"></i></button> </div>
            }
        );
    });
    

    return (
        <React.Fragment>
            {state.loading ? <LoadingIndicator loading={state.loading} /> : ''}
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="page-title-box">
                                <h2>Subscriptions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 nopadding">
                            <DataTable data={dataTable} />
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={state.openModal}
                    toggle={() => { setState({...state, openModal: false }) }}
                    centered={true}
                    size="xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">INVOICE ID:{'000000' + state.details.id}</h5>
                        <button
                            type="button"
                            onClick={() => { setState({...state, openModal: false }) }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            { state.spinner && <div id="cover-spin"></div> }
                            <embed src={state.invoice_link} type="application/pdf" height="700px" width="100%" />
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
});

export default Subscriptions;
