import React, { useState } from 'react';
// import Pace from 'react-pace-progress';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/jiffi-logo-white.png";
import logoLightSvg from "../../assets/images/jiffi-logo-white.svg";
import logoDark from "../../assets/images/logo-dark.png";
import iconLogout from "../../assets/images/iconLogout.png";

//i18n
import { withNamespaces } from 'react-i18next';
// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";

import CreditBalance from '../Common/CreditBalance';
import {
    getCookie
} from '../../Utils/helper';

let userDetails = getCookie("userDetails");
let loginUser = {
    id: '',
    firstname : '',
    surname: '',

};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}
const Header = (props) => {

    const [search, setsearch] = useState(false);
    const [megaMenu, setmegaMenu] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }
    return (
        <React.Fragment>
            <header id="page-topbar">
                {/* <Pace color="#27ae60"/> */}
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="17" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="" height="40" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLightPng} alt="" height="19" />
                                </span>
                            </Link>
                        </div>
                        <button type="button" onClick={() => { tToggle() }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <CreditBalance />
                        
                    </div>
                    <div className="d-flex">
                        <h3 className="header-title">{getCookie("company")}</h3>
                    </div>
                    <div className="d-flex">

                        <div className="Aligner profile-name ">
                            <div className="Aligner-item">
                                {loginUser.firstname || ''} {loginUser.surname || ''}
                                <div className="text-right"><small>Contact ID: {loginUser.id || ''}</small></div>
                            </div>
                        </div>
                        <ProfileMenu />
                        <div className="top-line-spacer" ></div>
                        {/* <NotificationDropdown /> */}
                        <div className="logout-button header-item ">
                            <Link  to="/logout" className="btn noti-icon  waves-effect">
                                <img src={iconLogout} />
                            </Link>
                        </div>
                        {/* <div onClick={() => { props.showRightSidebarAction(!props.showRightSidebar); }} className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="bx bx-log-out"></i>
                            </button>
                        </div> */}

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, { showRightSidebarAction, toggleLeftmenu, changeSidebarType })(withNamespaces()(Header));
