import React from "react";
import { Route,Redirect,withRouter } from "react-router-dom";
import queryString from 'query-string';
import {
    getCookie
} from '../../Utils/helper';


const Authmiddleware = ({
	component: Component,
	layout: Layout
}) => (
		<Route
			render={props => {
			// here you can apply condition
			    if (!getCookie("userDetails")) {
					if(props.location.pathname !== '/ticket-extension'){
						return (
							<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
						);
					}else{
						window.location.href = `login?next=${window.location.href}`;
					}
					
				}
				
				return (
		     	<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default withRouter(Authmiddleware);

