import React, {Component} from "react";
// import moment from 'moment';
// import { Link } from "react-router-dom";
// import HtmlParser from 'react-html-parser';
// import { TabContent, TabPane, Collapse, NavLink, NavItem, Nav, Row, Col, Container, Table, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, Modal, Card, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Label, InputGroup } from "reactstrap";
import {TabContent, TabPane, NavLink, NavItem, Nav} from "reactstrap";
import classnames from "classnames";
import TableContainer from "./Table";
import AddTicket from "./AddTicket";
import TicketDetails from "./TicketDetails";
import ApproveTicket from "./ApproveTicket";
// import DataTable from "../../components/DataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import Api from "../../Utils/API";
import {getCookie, setCookie} from '../../Utils/helper';
import {handleErrors} from '../../Utils/handleError';
import Swal from "sweetalert2";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";

let projects = getCookie("projects");
let project = '';
if (projects) {
    project = (JSON.parse(projects));
}

class Ticket extends Component {
    constructor() {
        super();
        this.state = {
            isNotification: false,
            newTicket: false,
            ticketDetails: false,
            loading: false,
            customActiveTab: '1',
            list: [],
            comments: {loading: false, value: []},
            filteredList: [],
            details: [],
            q: '',
            isSearch: false,
            project_id: project.length > 0 ? project[0].id : '',
            currentCount: 0,
            approveTicket: false,
            notificationSettings: { approvalNotification: true, generalNotification: true}
        };
        var node = null
        this.toggleNav = this.toggleNav.bind(this);
        this.newTicket = this.newTicket.bind(this);
        this.ticketDetails = this.ticketDetails.bind(this);
        this.approveTicketHandler = this.approveTicketHandler.bind(this);
        this.confirmApprove = this.confirmApprove.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.changeNotificationSetting = this.changeNotificationSetting.bind(this);
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    componentDidMount() { 
        this.getList();
        this.getWebsiteOptions();
        this.getTemplateOptions();
        this.intervalId = setInterval(this.timer.bind(this), 5000);

        //get updated settings cookie
        let userSettings = getCookie("settings");
        let setting = { approvalNotification: true, generalNotification: true}; 
        this.setState({notificationSettings: setting});
        if (userSettings && userSettings !== undefined && userSettings !== 'undefined' && userSettings !== 'null') {
            setting = JSON.parse(userSettings)
            this.setState({notificationSettings: setting})
        }
    }

    timer() { 
        if(this.state.ticketDetails){
            Api.getComment(this.state.details.id).then(result => {
                let main = result.data;
                const comments = main.replies ?? [];
                if(main.is_public) {
                    delete main.replies;
                    comments.push(main);
                }
                this.setState({comments:{loading:false,value:comments}});
            }).catch(error => {
                console.log(error);
                handleErrors();
            });
        }
    }

    toggleNotification = () => {
        this.setState({ isNotification: !this.state.isNotification });
    }

    updateSetting = async () => {
        this.setState({loading: true});
        const res = await Api.updateSetting(JSON.stringify(this.state.notificationSettings))
        if(res){
            setCookie(["settings", res.data.settings]); //update user settings cookie
        }
        this.showTost();
        this.setState({loading: false});
    }

    changeNotificationSetting = (name) => {
        const notificationSettingsCopy = {...this.state.notificationSettings};
        notificationSettingsCopy[name] = !notificationSettingsCopy[name];
        this.setState({ notificationSettings: notificationSettingsCopy });
        this.updateSetting(notificationSettingsCopy); //pass the latest notification settings
        
    }

    updateSetting = async (setting) => {
        const res = await Api.updateSetting(JSON.stringify(setting))
        if(res){
            setCookie(["settings", res.data.settings]); //update user settings cookie
        }
    }

    handleOutsideClick = (e) => {
        if(!this.node || !this.state.ticketDetails || this.node.contains(e.target)) return
        this.setState({ ticketDetails: false, isNotification: false });
      }

    getList() {
        this.setState({ loading: true });
        if(this.state.project_id) {
            Api.getTicket(this.state.project_id).then(result => {
                setTimeout(() => {
                    this.setState({ list: result.data, loading: false });
                }, 1000)
            }).catch(error => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong!',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            });
        }
    }

    getWebsiteOptions() {
        this.setState({ loading: true });
        Api.getWebsiteOptions().then(result => {
            setTimeout(() => {
                this.setState({ websites: result.data, loading: false });
            }, 1000)
        }).catch(error => {
            handleErrors();
        });
    }

    getTemplateOptions() {
        this.setState({ loading: true });
        Api.getTemplateOptions().then(result => {
            setTimeout(() => {
                this.setState({ templates: result.data, loading: false });
            }, 1000)
        }).catch(error => {
            handleErrors();
        });
    }

    toggleNav(e) {
        this.setState({ customActiveTab: e })
    }

    newTicket(data) {
        // check if projects is null or empty
        if(this.state.project_id === "") {
            console.log(project)
            setCookie(["projects", JSON.stringify(project), 2]);
            this.setState({project_id: data[0].id});
        }
        this.getList();
    }

    ticketDetails(e) {
        this.setState({ comments: { loading: true,  value: [] } });
        let comment_replies = [];
        // let attachments = e.attachments; 
        let attachments = [];
        Api.getComment(e.id).then(result => {   
            const comments_length = Object.keys(result.data).length;
            if(comments_length > 0) {
                let main = result.data;
                comment_replies = main.replies;
                delete main.replies;
                comment_replies.push(main); 
                // attachments = main.attachments;
            } 
            comment_replies.forEach(function (item, index) {
                item.attachments.map((att) => {
                    attachments.push(att);
                });
            }); 
            e.attachments = attachments; 
            // console.log("----------------------------------")
            // console.log(comment_replies)
            // console.log(e.attachments);
            // console.log("----------------------------------")

            this.setState({ ticketDetails: true, details: e, comments: { loading: false,  value: comment_replies } });
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    newComent(e){
        this.setState({comments:{loading:true,value:this.state.comments.value}});
        Api.getComment(this.state.details.id).then(result => {     
            let main = result.data;
            const comments = main.replies;
            if(comments) {
                delete main.replies;
                comments.push(main);
                this.setState({comments:{loading:false,value:comments}});
            }
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    searchAction(e) {
        let keywords = e.target.value;
        const q = keywords.toLowerCase();
        if (keywords !== '') {
            this.setState({ isSearch: true });
            this.setState({ q }, () => this.filterList());
        } else {
            this.setState({ isSearch: false });
            this.setState({ list: this.state.list, isLoading: false });
        }
    }

    approveTicketHandler() {
        console.log("its approval")
        this.setState({ approveTicket: true });
        console.log(this.state.approveTicket)
    }

    confirmApprove(){
        let new_status_id = 3;
        if(this.state.details.status_id === 7) new_status_id = 18;
        else if(this.state.details.status_id === 19) new_status_id = 8;
        console.log("confirm approve");
        this.setState({ loading: true});

        Api.approveTicket({task_id:this.state.details.id,status_id:new_status_id}).
        then(result => { 
            console.log(result);
            const lastTicketIndex =  this.state.list.indexOf(this.state.details)
            this.setState(prevState => {
                let bufferList = prevState.list
                bufferList = bufferList.map((item,i)=>
                    {
                    return i === lastTicketIndex ? { ...item,['status_id']: 5, ['status']: {id: 5, status_title: 'Request Received' }}:item
                    }
                )
                return { list: bufferList, approveTicket: !this.state.approveTicket, ticketDetails: false }
            });
            this.getList();
        })
        .catch(error => {
            console.log(error);
            return { loading: false };
            handleErrors();
        });
    }

    //list.requestor.firstname.toLowerCase().indexOf(q) !== -1 || 

    filterList() {
        let lists = this.state.list;
        let q = this.state.q;
     
        lists = lists.filter(function (list) { 
            if ( list.text ? list.text.toLowerCase().indexOf(q) !== -1 :  '' || list.pricing_method.name.toLowerCase().indexOf(q) !== -1) {
                return true;
            } else {
                return false;
            }
        });
        this.setState({ filteredList: lists, isLoading: false });
    }

    showUploadError = (message, arr) => {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK'
        });
        // let list = `<br><ul>`;
        // arr.forEach(el => {
        //     list += `<li>${el.name} - ${el.size}</li>`;
        // });
        // list += `<\\ul>`;
        // Swal.fire({
        //     showCloseButton: true,
        //     showConfirmButton: false,
        //     customClass: {
        //         content: 'popupForm-QA-content',
        //         popup: 'popupForm-modal',
        //         closeButton: 'popupForm-close'
        //     },
        //     html: `<ul>${list}<ul>`
        //     text: `Are you sure you want to delete this ${props.page}? You can't undo this action.`,
        // }).then((res) => {
        //     // if(res.isConfirmed){
        //     //     state.report_details = report;
        //     //     updateStatusAPI(params, e);
        //     // }
        //     alert('err');
        // })
    }

    render() {
        let list = this.state.list;
        let completedTask =   0 ;
        let currentTask = 0;
        if (this.state.isSearch) {
            list = this.state.filteredList;
        }

        for (let index = 0; index < list.length; index++) {
            if(list[index].status) {
                if(list[index].status.id === 7 || list[index].status.id === 8){
                    completedTask++;
                }else{ 
                    currentTask++;
                }
            }
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                {/* <LoadingIndicator loading={this.state.loading}/> */}
                <div className="page-content" onClick={this.handleOutsideClick}>
                    <div className="container-fluid">
                        <div ref={(node) => { this.node = node; }}>
                        {
                            this.state.ticketDetails ?
                                (
                                    <TicketDetails
                                        close={() => { this.setState({ ticketDetails: !this.state.ticketDetails, isNotification: false }); }}
                                        approve={this.approveTicketHandler}
                                        details={this.state.details}
                                        comments={this.state.comments}
                                        newComent={this.newComent.bind(this)}
                                        attachments={this.state.attachments}
                                        ref={(node) => { this.setState({ node: node })  }}
                                        toggleNotification={this.toggleNotification}
                                        isNotification={this.state.isNotification}
                                        notificationSettings={this.state.notificationSettings}
                                        changeNotificationSetting={this.changeNotificationSetting}
                                    />
                                )
                                : ''
                        }
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title-box">
                                    <h2>My Tickets  </h2>
                                    <div className="circle">
                                        <span>{this.state.list.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-action-container">
                                    <div className="row-1">
                                        <div className="form-group has-search search-input text-right">
                                            <span className="fa fa-search form-control-feedback"></span>
                                            <input onChange={this.searchAction.bind(this)} type="text" className="form-control" placeholder="Search" />
                                        </div>
                                    </div>
                                    <div className="row-2">
                                        <button onClick={() => { this.setState({ newTicket: !this.state.newTicket }) }} className="btn btn-primary" > <i className="fa fa-plus" ></i> Add New Ticket</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Nav tabs className="nav-tabs-custom">
                            {/* <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "1"
                                    })}
                                    onClick={() => {
                                        this.toggleNav("1");
                                    }}
                                >
                                    <span className="d-sm-block">All Tasks ({this.state.list.length})</span>
                                </NavLink>
                            </NavItem> */}
                            <NavItem className="navlink-second" >
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "1"
                                    })}
                                    onClick={() => {
                                        this.toggleNav("1");
                                    }}
                                >
                                    <span className="d-sm-block">Current Tasks ({currentTask})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="navlink-second">
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "2"
                                    })}
                                    onClick={() => {
                                        this.toggleNav("2");
                                    }}
                                >
                                    <span className="d-sm-block">Completed Tasks ({completedTask})</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div className="row">
                            <div className="col-lg-12 nopadding">
                                <TabContent activeTab={this.state.customActiveTab}>
                                    {/* <TabPane tabId="1" className="p-3">
                                        <TableContainer
                                            list={list}
                                            type={1}
                                            ticketDetails={this.ticketDetails}
                                        />
                                    </TabPane> */}
                                    <TabPane tabId="1" className="p-3">
                                        <TableContainer
                                            list={list}
                                            type={1}
                                            ticketDetails={this.ticketDetails}
                                            approveTicketHandler={this.approveTicketHandler}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2" className="p-3">
                                        <TableContainer
                                            list={list}
                                            type={2}
                                            ticketDetails={this.ticketDetails}
                                            approveTicketHandler={this.approveTicketHandler}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
                <AddTicket
                    open={this.state.newTicket}
                    hideModal={event => this.setState({ newTicket: false })}
                    new={this.newTicket}
                    project_id={this.state.project_id}
                    showUploadError={this.showUploadError}
                    websites={this.state.websites ?? []}
                    templates={this.state.templates ?? []}
                    notificationSettings={this.state.notificationSettings}
                    changeNotificationSetting={this.changeNotificationSetting}
                />
                <ApproveTicket open={this.state.approveTicket} hideModal={() => this.setState({ approveTicket: false })} onConfirm={this.confirmApprove} isLoading={this.state.loading} />
            </React.Fragment>
        );
    }
}

export default Ticket;
