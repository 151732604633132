import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Avatar from 'react-avatar';
import {
    getCookie
} from '../../Utils/helper';
import Api from "../../Utils/API";

import AddUser from "./AddUser";

//Import Image
import iconMenu from "../../assets/images/iconMenu.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
    handleErrors
} from '../../Utils/handleError';
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import EditUser from "./EditUser";
import ConfirmDelete from "../../components/ConfirmDelete";
import Swal from "sweetalert2";

let userDetails = getCookie("userDetails");
let loginUser = {};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}
//console.log('loginUser', loginUser.company.invite_link);
const LoadingDiv = () => {
    return (
        <React.Fragment>
            <div className="w-400 justify-content-center" >
                <div className="user-div" >
                    <div className="row-1">
                        <Skeleton circle={true} height={65} width={65} count={1} />
                    </div>
                    <div className="row-2">
                        <ul>
                            <li className="name"> <Skeleton height={20} width={120} count={1} /></li>
                            <li className="email mt-2"> <Skeleton height={12} width={120} count={1} /></li>
                        </ul>
                    </div>
                    <div className="row-3">
                        <div className="ripple menu-button"> <Skeleton circle={true} height={30} width={30} count={1} /></div>
                    </div>
                </div>
            </div>
            <div className="w-400 justify-content-center" >
                <div className="user-div" >
                    <div className="row-1">
                        <Skeleton circle={true} height={65} width={65} count={1} />
                    </div>
                    <div className="row-2">
                        <ul>
                            <li className="name"> <Skeleton height={20} width={100} count={1} /></li>
                            <li className="email mt-2"> <Skeleton height={12} width={100} count={1} /></li>
                        </ul>
                    </div>
                    <div className="row-3">
                        <div className="ripple menu-button"> <Skeleton circle={true} height={30} width={30} count={1} /></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

class Card_management extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            newTicket: false,
            editUser: false,
            customActiveTab: '1',
            selectedFiles: [],
            defaultDate: new Date(),
            list: [],
            company_id: loginUser.company ? loginUser.company.id : '',
            inviteLink: '',
            editUserDetails: ''
        };
    }

    

    componentDidMount() { 
        let baseurlINK = `${this.getBaseUrl()[0]}invite-link/${loginUser.company.invite_link}`;
        this.setState({ inviteLink: baseurlINK  });
        this.getList();
    }

    getBaseUrl() {
        return window.location.href.match(/^.*\//);
    }

    getList() {
        this.setState({ loading: true });
        Api.getUser().then(result => {
            setTimeout(() => {
                this.setState({ list: result.data, loading: false }); 
            }, 1000);
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    newSave() {
        this.setState({ newTicket: false });
        this.getList();
    }

    updateSave(){
        this.setState({ editUser: false });
        this.getList();
    }

    deleteUser(user){
        let params = { id: user.id }
        let array = [...this.state.list]; // make a separate copy of the array
        const index = array.indexOf(user)

        if (index !== -1) {
            array.splice(index, 1);
            
            Api.changeUserStatus(params).then(result => {
                Swal.fire( 'Deleted!', 'Email has been deleted.', 'success' )
                this.setState({list: array})
            }).catch(error => {
                console.log(error);
            });
        }
    }


    confirmDelete(event, user){
        let confirm_details = { page: 'user' }
        
        ConfirmDelete(confirm_details).then(res => {
            if (res.isConfirmed) {
                this.deleteUser(user);
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title-box">
                                    <h2>User Managements</h2>
                                </div>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="right-action-container">
                                    <div className="row-1">
                                        
                                    </div>
                                    <div className="row-2">
                                    <button onClick={() => { this.setState({ newTicket: !this.state.newTicket }) }} className="btn btn-primary" > <i className="fa fa-plus" ></i> Add New User</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-card ">
                            {this.state.loading ? <LoadingDiv /> : ''}
                            {this.state.list.map((item, i) => {
                                return (
                                    <div className="w-400 justify-content-center" key={i} >
                                        <div className="user-div" >
                                            <div className="row-1">
                                                <Avatar name={item.firstname} round={true} size={65} color="#A6B0CF" src={""} />
                                            </div>
                                            <div className="row-2">
                                                <ul>
                                                    <li className="name">{item.firstname} {item.surname}</li>
                                                    <li className="email">{item.email}</li>
                                                </ul>
                                            </div>
                                            <div className="row-3">
                                            <UncontrolledDropdown className="ripple-dropdown menu-button" onClick={e => e.stopPropagation()}>
                                                <DropdownToggle className="arrow-none" tag="i">
                                                    <img src={iconMenu} />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem className="dropdown-edit" onClick={() => { this.setState({ editUser: !this.state.editUser, editUserDetails: item }) }}>
                                                        <i className="fa fa-edit"></i>
                                                        <span>Edit</span>
                                                    </DropdownItem>
                                                    <DropdownItem className="dropdown-delete" onClick={(e) => {this.confirmDelete(e, item)}}>
                                                        <i className="fa fa-trash"></i>
                                                        <span>Delete</span>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                                {/* <div className="ripple menu-button"><img src={iconMenu} className="" /></div> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <AddUser
                    open={this.state.newTicket}
                    hideModal={event => this.setState({ newTicket: false })}
                    newSave={this.newSave.bind(this)}
                    inviteLink={this.state.inviteLink}
                />
                <EditUser
                    open={this.state.editUser}
                    userDetails={this.state.editUserDetails}
                    hideModal={event => this.setState({ editUser: false })}
                    updateSave={this.updateSave.bind(this)}
                />
            </React.Fragment>
        );
    }
}

export default Card_management;
