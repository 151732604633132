import React from 'react';
import { Switch, BrowserRouter as Router,Route, HashRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { userRoutes , authRoutes, globalRoutes } from "./routes/allRoutes";  // Import Routes all
import Authmiddleware from "./routes/middleware/Authmiddleware";  // Import all middleware
import "./assets/scss/theme.scss";  // Import scss
import TagManager from 'react-gtm-module';  // import Google Tag Manager

const tagManagerArgs = {
    gtmId: 'GTM-5P5F7R5',
}
TagManager.initialize(tagManagerArgs)

const App = (props) => {
	React.useEffect(() => {
		TagManager.dataLayer(tagManagerArgs);
	});

	function getLayout() {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	const Layout = getLayout();

	const NonAuthmiddleware = ({
		component: Component,
		layout: Layout,
		prev
	}) => (
			<Route
				render={props => {
				return (
						<Layout>
							<Component {...props} prev={prev} />
						</Layout>
					);
				}}
			/>
		);

	return (
		<React.Fragment>
			<Router>
				<Switch>
					{globalRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={Layout}
							component={route.component}
							key={idx}
						/>
					))}

					{authRoutes.map((route, idx) => (
						<NonAuthmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							prev={window.location.href}
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={Layout}
							component={route.component}
							key={idx}
						/>
					))}
				</Switch>
			</Router>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
