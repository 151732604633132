import moment from 'moment';
import React, { useState } from 'react';
import Avatar from '../../components/Avatar'
import HtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import AttachmentFile from '../../components/AttachmentFile';
import { getFirstWord } from '../../Utils/helper';
import iconAttachment from "../../assets/images/icon-attachment.png";

export const CommentSection = (props) => {
    const LoadingDiv = () => {
        return (
            <React.Fragment>
                <div className="communication-theard" >
                    <div className="communication-image">
                        <Skeleton circle={true} height={48} width={48} count={1} />
                    </div>
                    <div className="communication-content" style={{ minWidth: '86%' }} >
                        <div className="row">
                            <div className="col-sm-6">
                                <h4><Skeleton height={20} width={150} count={1} /></h4>
                                <h5><Skeleton height={12} width={150} count={1} /></h5>
                            </div>
                            <div className="col-sm-6 date-message">
                                <Skeleton height={12} width={150} count={1} />
                            </div>
                            <div className="col-lg-12">
                                <Skeleton height={12} count={3} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {/* Comment Thread */}
            {props.comments.map((item, i) => {
                return (
                    <div className="communication-theard" key={i}  >
                        {item.email_tag_id != 4 && 
                            <>
                                <div className="communication-image">
                                    <Avatar
                                        name={item.sender.firstname ? `${getFirstWord(item.sender.firstname)} ${getFirstWord(item.sender.lastname || item.sender.surname)}` : `${getFirstWord(item.sender.email)}`}
                                        round={true}
                                        size="48"
                                        color="#f44336"
                                        className="btn-cirle-avatar"
                                    />
                                </div>
                                <div className="communication-content">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>{item.sender.firstname ? `${getFirstWord(item.sender.firstname)} ${getFirstWord(item.sender.lastname || item.sender.surname)}` : `${getFirstWord(item.sender.email)}`}</h4>
                                        </div>
                                        <div className="col-sm-6 date-message">
                                            {moment(item.created_at).format('MMMM DD, YYYY ' + ' h:mm A')}
                                        </div>
                                        <div className="row-message-data ml-3">
                                            {HtmlParser(item.body)}
                                        </div> 
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            {item.attachments.map((item, i) => {
                                                return (
                                                    <div className="col-md-12" key={i} >
                                                        <AttachmentFile data={item} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                );
            })}
            
            { props.loading && <LoadingDiv /> }
        </React.Fragment>
    );
}
        
export default CommentSection;