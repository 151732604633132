import React from 'react';


export default function TextInput(props) {

    var defaultProps = {}; 
    if(props.error){
        defaultProps = {
            error: true,
            label: "text-danger",
            input: "is-error-input"
        };
    }

    return (
        <div className="form-group" >
            <label htmlFor="inputLabel"  className={defaultProps.label} >{props.label}</label>
            <div className="input-group" style={props.styles}>
            <input  onChange={props.handleChange} name={props.name} type={props.type} value={props.value}   placeholder={props.placeholder} className={`form-control ${defaultProps.input}`}  />
            {
                props.isPassword === true?
                (
                    <div className="input-group-prepend password-field">
                        <span onClick={props.tooglePassword}  className="password-icon" ><i className={props.icon} aria-hidden="true"></i></span>
                    </div>
                )
                :''

            }
            </div>
            <span className={`help-block ${defaultProps.label}`} >{props.error}</span>
        </div>
    )

}