import React, { Component } from "react";
import moment from 'moment';
import Switch from "react-switch";
import { Row, Col, Modal, Card, FormGroup, Label, InputGroup } from "reactstrap";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import * as markerjs2 from 'markerjs2';
import LoadingIndicator from "../../components/LoadingIndicator";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Api from "../../Utils/API"; 
import imageIcon from "../../assets/images/ticket-notification.png";
import {
    handleErrors
} from '../../Utils/handleError';
import {
    UniversalValidator,
    getCookie
} from '../../Utils/helper';
import eraserIcon from "../../assets/images/eraserIcon.png";
import rectangleIcon from "../../assets/images/rectangleIcon.png";
import coverIcon from "../../assets/images/coverIcon.png";
import highlightIcon from "../../assets/images/highlightIcon.png";
import lineIcon from "../../assets/images/lineIcon.png";
import arrowIcon from "../../assets/images/arrowIcon.png";
import textIcon from "../../assets/images/textIcon.png";
import circleIcon from "../../assets/images/circleIcon.png";
import TextInput from "../../components/TextInput";
import RichText from '../../components/RichText';
import freeHandIcon from "../../assets/images/Freehand.png";

let markerArea = null;
const styles = {
    border: '1px solid #E6E7EF', 
};
let projects = getCookie("projects");
let project = '';
if (projects) {
    project = (JSON.parse(projects));
}

class TicketExtension extends Component {
    imgRef = React.createRef();
    imgCanvas = React.createRef(null);

    
    constructor(props) {
        super(props);
        let currentDate = new Date();
        var date = new Date();
        date.setDate(date.getDate() + 1);
        this.state = {
            loading: false,
            isAnnotation: false,
            isSubmit: false,
            isNew: true,
            attachment: '',
            clearRich: '',
            description: '',
            dueDate: date,
            dueDateError: '',
            time: '06:00:00',
            name: '',
            name2: '',
            project_id: project ? project[0].id : '',
            ticket_options: [],
        };
        this.saveImage = this.saveImage.bind(this);
        this.cancelImage = this.cancelImage.bind(this);
        this.handleChangeRich = this.handleChangeRich.bind(this);
        this.submitTicket = this.submitTicket.bind(this);
        this.nameChange = this.nameChange.bind(this);
    }
 

    componentDidMount() {
        this.getList();
        let params = queryString.parse(this.props.location.search);
        this.toDataUrl(params.key, (myBase64) => {
            this.setState({ attachment: myBase64})
        });
        window.addEventListener('load', this.showMarkerArea());
        this.setState({ isAnnotation: true });
    }

    showMarkerArea() {
        if (this.imgRef.current !== null) {
            // create a marker.js MarkerArea
            markerArea = new markerjs2.MarkerArea(this.imgRef.current);

            markerArea.targetRoot = this.imgCanvas.current

            markerArea.uiStyleSettings.hideToolbar = true
            markerArea.uiStyleSettings.hideToolbox = true
            // attach an event handler to assign annotated image back to our image element
            markerArea.addRenderEventListener((dataUrl) => {
                if (this.imgRef.current) {
                    this.imgRef.current.src = dataUrl;
                    this.setState({attachment: dataUrl});
                }
            });

            // launch marker.js
            markerArea.show();
        }
    }

    getList() {
        this.setState({ loading: true });
        let ticket_options = [];
        Api.getTicket(this.state.project_id).then(result => {
            console.log(result.data);
            setTimeout(() => {
                this.setState({ list: result.data, loading: false });
            }, 1000)
            let list = result.data;
            for (let index = 0; index < list.length; index++) {
                ticket_options.push({
                    value: list[index].id,
                    label: list[index].text,
                });

            }
            this.setState({ ticket_options: ticket_options, list: result.data });

        }).catch(error => {
            handleErrors();
        });
    }

    toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    addRow() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.ArrowMarker);
        }
    }

    addText() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.TextMarker);
        }
    }

    addHighLight() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.HighlightMarker);
        }
    }

    addLine() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.LineMarker);
        }
    }

    addCover() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.CoverMarker);
        }
    }

    addRectangle() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.FrameMarker);
        }
    }

    addCircle() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.EllipseMarker);
        }
    }

    erase() {
        if (markerArea) {
            markerArea.deleteSelectedMarker();
        }
    }

    addFreehandMarker() {
        if (markerArea) {
            markerArea.createNewMarker(markerjs2.FreehandMarker);
        }
    }

    saveImage() {
        this.setState({ isAnnotation: false });
        if (markerArea) {
            markerArea.addEventListener('render', ({ dataUrl, state }) => {
                const res = document.getElementById("resultImagedata");
                res.src = dataUrl;
                this.setState({ attachment: dataUrl });
            })
            markerArea.startRenderAndClose();
        }
    }

    cancelImage() {
        this.setState({ isAnnotation: false });
    }

    handleChangeRich(e) {
        this.setState({ description: e, clearRich: false });
    }

    submitTicket() {
        let duedate = moment(this.state.dueDate).format("YYYY-MM-DD");
        const data = new FormData();
        let duteDateTime = moment(this.state.dueDate + ' ' + this.state.time).format("YYYY-MM-DD  HH:mm:ss");
        let nameError = '';

        if (this.state.isNew) {
            nameError = UniversalValidator({ value: this.state.name, isValidate: true, isUrl: false, errorMsg: 'Ticket Name is required.' });
        } else {
            nameError = UniversalValidator({ value: this.state.name2 ? this.state.name2.value : '', isValidate: true, isUrl: false, errorMsg: 'Please select Ticket.' });
        }

        if (nameError) {
            this.setState({ nameError: nameError });
        } else {
            this.setState({ isSubmit: true, isAnnotation: false }); 
                
            if (markerArea) {

                data.append('name', this.state.name);
                data.append('description', this.state.description);
                // data.append('due_date', duedate);
                // data.append('time', this.state.time);
                // data.append('files[]', file);
                data.append('project_id', this.state.project_id); 
                
                markerArea.addEventListener('render', ({ dataUrl, state }) => {
                    const res = document.getElementById("resultImagedata");
                    res.src = dataUrl; 
                    this.setState({ attachment: dataUrl });

                    let file = this.dataURLtoFile(dataUrl, 'attachment.png');

                    setTimeout(() => {
                        if (this.state.isNew) {
                            data.append('name', this.state.name);
                            data.append('description', this.state.description);
                            // data.append('due_date', duedate);
                            // data.append('time', this.state.time);
                            data.append('files[]', file);
                            data.append('project_id', this.state.project_id);
                            
                            Api.saveTicket(data).then(result => {
                                //window.location.href = "/ticket"; 
                                this.showTost();
                            }).catch(error => {
                                handleErrors();
                                this.setState({ isSubmit: false});
                            });
                        } else {
                            data.append('task_id', this.state.name2.value);
                            data.append('description', this.state.description);
                            // data.append('due_date', duedate + ' ' + this.state.time);
                            //data.append('time', this.state.time);
                            data.append('file[]', file);
                            data.append('project_id', this.state.project_id);
        
                            Api.updateAttachment(data).then(result => {
                                window.location.href = "/ticket";
                            }).catch(error => {
                                handleErrors();
                                this.setState({ isSubmit: true});
                            });
                        }
        
                    }, 1000);
                })
            }else{
                this.setState({ isSubmit: true});
            }

            markerArea.startRenderAndClose();
        }
    }
    

    nameChange(e) {
        let list = this.state.list
        const objIndex = list.find(obj => obj.id === parseInt(e.value));
        this.setState({ name2: e, description: objIndex.description });
    }

    
    
    showTost() {
        // Get the snackbar DIV

        // Add the "show" class to DIV
        var x = document.getElementById("snackbar");
        x.className = "show row notification-div";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {   
            window.location.href = "/ticket";
        }, 4000);
    }

    closeToast() {
        var x = document.getElementById("snackbar");
        x.className = x.className.replace("show", "");
    }


    render() {


        return (
            <React.Fragment>
                <div id="snackbar" onClick={this.closeToast} className="display-none" >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>Success!</h5>
                        <p>You successfully created a new ticket!</p>
                    </div>
                </div>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                <div className="page-content">
                    <div className="container-fuid" style={{ width: '100vw' }}>
                        <div className="container-flex">
                            <div className="column left row-1">
                                <div
                                    className="image-annotation"
                                    ref={ this.imgCanvas }
                                >
                                    <img
                                        ref={ this.imgRef }
                                        alt="to-annotate"
                                        onClick={ () => this.showMarkerArea() }
                                        src={ this.state.attachment }
                                        id="resultImagedata"
                                    />
                                </div>
                            </div>
                            <div className="column right row-2">
                                <div className="annotation-action-div">
                                    <div className="header-text">
                                        Create New Ticket
                                    </div>
                                    <div className="marker-div-container">
                                        <div className="header-subtext">
                                            1. Add Annotation
                                        </div>

                                        <div className="marker-div">
                                            <div className="toolbar-button ripple" onClick={this.addRow.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={arrowIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Arrow
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addFreehandMarker.bind(this)}>
                                                <div className="menu-inside ">
                                                    <div>
                                                        <img src={freeHandIcon} width={29} height={29} />
                                                    </div>
                                                    <div className="text">
                                                        Freehand
                                                </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.erase.bind(this)}>
                                                <div className="menu-inside ">
                                                    <div>
                                                        <img src={eraserIcon
                                                        } />
                                                    </div>
                                                    <div className="text">
                                                        Eraser
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addRectangle.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={rectangleIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Rectangle
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addCircle.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={circleIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Circle
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addCover.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={coverIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Cover
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addHighLight.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={highlightIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Highlight
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addLine.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={lineIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Line
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toolbar-button ripple" onClick={this.addText.bind(this)}>
                                                <div className="menu-inside">
                                                    <div>
                                                        <img src={textIcon} />
                                                    </div>
                                                    <div className="text">
                                                        Text
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="header-subtext">
                                            2. Fill out Ticket Information
                                        </div>
                                        <div className="ticket-info">
                                            <div>
                                                <div className="d-flex">
                                                    <div style={{ paddingTop: 3, paddingRight: 5 }} >New Ticket? </div>
                                                    <div className="square-switch">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch1"
                                                            switch="none"
                                                            checked={this.state.isNew}
                                                            onChange={() => { this.setState({ isNew: !this.state.isNew, nameError: '' }) }}
                                                        />
                                                        <label
                                                            htmlFor="square-switch1"
                                                            data-on-label="Yes"
                                                            data-off-label="No"
                                                        ></label>
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                this.state.isNew ?
                                                    <TextInput
                                                        styles={styles}
                                                        name="name"
                                                        type="text"
                                                        label="Ticket Name"
                                                        placeholder="Enter Ticket Name"
                                                        error={this.state.nameError}
                                                        value={this.state.name}
                                                        handleChange={event => this.setState({ name: event.target.value, nameError: '' })}
                                                    />

                                                    :

                                                    <>
                                                        <Label>Ticket</Label>
                                                        <Select
                                                            className="form-select mb-2"
                                                            value={this.state.name2}
                                                            onChange={this.nameChange}
                                                            options={this.state.ticket_options}
                                                            placeholder="Select Ticket"
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                        />
                                                        <div style={{ marginTop: -5, marginBottom: 5 }}><span className={`help-block error`} >{this.state.nameError}</span></div>

                                                    </>
                                            }

                                            <Label>Description</Label>
                                            <RichText
                                                handleChange={this.handleChangeRich}
                                                clear={this.state.clearRich}
                                                value={this.state.description}
                                            />
                                            {/* <div className="row mt-2">
                                                <div className="col-lg-6">
                                                    <FormGroup className="mb-4">
                                                        <Label>Due Date</Label>
                                                        <InputGroup>
                                                            <DatePicker
                                                                className={`form-control `}
                                                                selected={this.state.dueDate}
                                                                onChange={event => this.setState({ dueDate: event })}
                                                            />
                                                        </InputGroup>
                                                        <img src={calendarIcon} className="calendar-icon" />
                                                        <span className={`help-block`} >{this.state.dueDateError}</span>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-6">
                                                    <FormGroup className="mb-4">
                                                        <Label>Time</Label>
                                                        <input onChange={event => this.setState({ time: event.target.value })} className="form-control" type="time" defaultValue={this.state.time} id="example-time-input" />
                                                    </FormGroup>
                                                </div>
                                            </div> */}
                                            <div className="mt-3">
                                                <button type="button" disabled={this.state.isSubmit} className="btn btn-primary waves-effect waves-light" onClick={this.submitTicket} >
                                                    {
                                                        this.state.isSubmit ?
                                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                            : ''
                                                    }
                                                    Create
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(TicketExtension);

