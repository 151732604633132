import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../store/actions';
import { setCookie, getCookie, deleteCookie } from '../../Utils/helper';
import { AdminUi } from '../../Utils/consts';

const Logout = (props) => {

    useEffect(() => {
        setCookie(["access_token", '']);
        setCookie(["projects", '']);
        setCookie(["userDetails", '']);
        setTimeout( ()=>{
            window.location.href = "/login";
        },1000);
    });

    return (
            <></>
          );
    }

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);
