

import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";


export const Editor = (props) => {
    const [value, setValue] = useState('');
    const handleChange = value => {
        setValue(value);
        props.handleChange(value);
    };

    useEffect(() => {
        if (props.clear) {
            setValue('');
        }
        if (props.value) {
            setValue(props.value);
        }
    
    }, [props]);

    return (
        <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
        </div>
    );
};

export default Editor;
