import React from 'react';
import circleGray from "../assets/images/circle-gray.png";
import checkIcon from "../assets/images/checkIcon.png";

export default function PricingOptions(props) {
    let item = props.items;

    const [check, setCheck] = React.useState(false);

    const selectPayment = (e) => {
        props.action({ id: item.id, plan: props.plan });
    }

    return (
        <React.Fragment>
            <div className={`ripple price-option ${item.check ? 'active-option' : ''}`} onClick={selectPayment}>
                <div className="column-1">
                    {item.name}
                    <div className={`checkbox`} >
                    </div>
                </div>
                <div className="column-2 currency-div">
                    <div><span className="currency">AU$</span> <span className="price">{(item.amount / 100).toFixed(2)}</span></div>
                    {
                        props.isSale ?
                            <div className="sale-div">Normally {(item.amount / 100).toFixed(2)}</div>
                            : ''
                    }
                </div>
            </div>
        </React.Fragment>
    )

}