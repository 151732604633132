import React from "react";
import Swal from 'sweetalert2'
import { Modal } from "reactstrap";
import TextInput from "../../components/TextInput";
import TextSelect from "../../components/TextSelect";
import {
    getCookie
} from '../../Utils/helper';
import {
    handleErrors
} from '../../Utils/handleError';
//Import Image
// import iconVisa from "../../assets/images/visa.png";
// import masterCard from "../../assets/images/master-card.png";
// import discoverCard from "../../assets/images/discover-card.png";
// import americanExpress from "../../assets/images/american-express.png";
import cardList from "../../assets/images/credit-card-list.png";
import imageIcon from "../../assets/images/ticket-notification.png";
import {
    UniversalValidator
} from '../../Utils/helper';
import Api from "../../Utils/API";

let userDetails = getCookie("userDetails");
let loginUser = {};
if(userDetails){
    loginUser = JSON.parse(userDetails);
}




export default function AddCard(props) {

    const year = (new Date()).getFullYear();
    let years = Array.from(new Array(20), (val, index) => index + year);
    let months = [
        { value: '01', month: 'January' },
        { value: '02', month: 'Febuary' },
        { value: '03', month: 'March' },
        { value: '04', month: 'April' },
        { value: '05', month: 'May' },
        { value: '06', month: 'June' },
        { value: '07', month: 'July' },
        { value: '08', month: 'August' },
        { value: '09', month: 'September' },
        { value: '10', month: 'October' },
        { value: '11', month: 'November' },
        { value: '12', month: 'December' },
    ]
        ;

    const [state, setState] = React.useState({
        card: '',
        cardError: '',
        year: '',
        yearError: '',
        cvv: '',
        cvvError: '',
        isSubmit: false,
        month: '',
        monthError: '',
        owner: '',
        ownerEror: '',

    });


    function handleSubmit(event) {
        event.preventDefault();
        const ownerError = UniversalValidator({ value: state.card, isValidate: true, isUrl: false, errorMsg: 'Owner is required.' });
        const cardError = UniversalValidator({ value: state.card, isValidate: true, isUrl: false, errorMsg: 'Card Number is required.' });
        const cvvError = UniversalValidator({ value: state.cvv, isValidate: true, isUrl: false, errorMsg: 'CVV is required.' });
        const yearError = UniversalValidator({ value: state.year, isValidate: true, isUrl: false, errorMsg: 'Expiration Year is required.' });
        const monthError = UniversalValidator({ value: state.month, isValidate: true, isUrl: false, errorMsg: 'Expiration Month is required.' });
        if (cardError || cvvError || yearError || monthError || ownerError) {
            setState({ ...state, cardError: cardError, cvvError: cvvError, yearError, yearError, monthError: monthError, ownerError: ownerError });
        }
        else {
            setState({ ...state, isSubmit: true });
            let params = {
                company_id: loginUser.company ? loginUser.company.id : '',
                firstname: loginUser.firstname,
                lastname: loginUser.surname,
                owner: state.owner,
                card_number: state.card.split(/\s/).join(''),
                cvv: state.cvv,
                exp_month: state.monthData,
                exp_year: state.year,
            }

            Api.addCard(params).then(result => {
                console.log(result.data);
                setState({ ...state, isSubmit: false });
                showTost();
                setState({
                    card: '',
                    cardError: '',
                    year: '',
                    yearError: '',
                    cvv: '',
                    cvvError: '',
                    isSubmit: false,
                    month: '',
                    monthError: '',
                    owner: '',
                    ownerEror: '',

                });
                props.addCard();

            }).catch(error => {
                if (error.response) {
                    Swal.fire({
                        title: 'Error!',
                        text: error.response.data['message'] ?? error.response.data,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                } else {
                    handleErrors();
                }
                setState({ ...state, isSubmit: false });
            });
        }
    }

    function creditCard(value) {
        let values = value.target.value;
        if (values.length < 20) {
            setState({ ...state, card: cc_format(value.target.value) });
        }

    }

    function cc_format(value) {
        const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
        const onlyNumbers = value.replace(/[^\d]/g, '')

        return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
            [$1, $2, $3, $4].filter(group => !!group).join(' ')
        )
    }

    function cvvChange(value) {
        let values = value.target.value;
        if (values.length < 5) {
            setState({ ...state, cvv: values });
        }

    }

    function numberOnly(value) {
        return value.replace(/[^\d]/g, '');

    }

    function changeMonth(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-id');
        setState({ ...state, month: e.target.value, monthData: option });
    }

    var x = document.getElementById("snackbar-user");
    function showTost() {
        x.className = "show row notification-div";
        setTimeout(function () { closeToast(); }, 5000);
    }

    function closeToast() {
        x.className = x.className.replace("show", "");
    }



    return (
        <React.Fragment>
            <div id="snackbar-user" onClick={closeToast} className="display-none" >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>Card successfully linked!</p>
                </div>
            </div>
            <Modal
                isOpen={props.open}
                toggle={props.hideModal}
                centered={true}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Add New Card</h5>
                    <button
                        type="button"
                        onClick={props.hideModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ margin: 10 }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <TextInput
                                name="owner"
                                type="text"
                                label="Owner"
                                placeholder="Owner"
                                error={state.ownerError}
                                value={state.owner}
                                handleChange={event => setState({ ...state, owner: event.target.value, ownerError: '' })}
                            />
                        </div>
                        <div className="col-lg-6">
                            <TextInput
                                name="card"
                                type="text"
                                label="Card Number"
                                placeholder="0000 0000 0000 0000"
                                error={state.cardError}
                                value={state.card}
                                handleChange={creditCard}
                            />
                            <div className="credit-card-imgs" >
                                <img src={cardList} />
                                {/* <img src={iconVisa} />
                                    <img src={masterCard} />
                                    <img src={americanExpress} />
                                    <img src={discoverCard} /> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <TextInput
                                name="cvv"
                                type="text"
                                label="CVV"
                                placeholder="123"
                                error={state.cvvError}
                                value={state.cvv}
                                handleChange={cvvChange}
                            />
                        </div>
                        <div className="col-lg-6" style={{ marginTop: 40 }} >
                            <TextSelect
                                name="month"
                                type="text"
                                label="Expiration Month"
                                placeholder="Select Month"
                                error={state.monthError}
                                data={months}
                                dataKey="month"
                                isObject={true}
                                value={state.month}
                                handleChange={changeMonth}
                            //handleChange={event => setState({ ...state, month: event.target.value, nameError: '' })}
                            />
                        </div>
                        <div className="col-lg-6" style={{ marginTop: 40 }}>
                            <TextSelect
                                name="year"
                                type="text"
                                label="Expiration Year"
                                placeholder="Select Year"
                                error={state.yearError}
                                data={years}
                                dataKey=""
                                value={state.year}
                                isObject={false}
                                handleChange={event => setState({ ...state, year: event.target.value, nameError: '' })}
                            />
                        </div>
                        <div className="col-lg-12">
                            <div className="text-right">
                                <button type="button" onClick={handleSubmit} className="btn btn-primary waves-effect waves-light" disabled={state.isSubmit} >
                                    {
                                        state.isSubmit ?
                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                            : ''
                                    }
                                        Link  Card
                                        </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}