import React from "react";
import Swal from 'sweetalert2'
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import iconAttachment from "../../assets/images/icon-attachment.png";
import alertCircle from "../../assets/images/alert-circle.svg";
import notificationBell from "../../assets/images/notification-bell.png";
import { checkObject, getFirstWord } from '../../Utils/helper';
import AttachmentFile from '../../components/AttachmentFile'
import Api from "../../Utils/API";
import Avatar from '../../components/Avatar'
import CommentSection from './comment-section';
import { useEffect, useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import RichText from '../../components/RichText';


var fileSaver = require('file-saver');

export default function TicketDetails(props) {
    console.log(props)
    let list = props.details;
    let time = list.pricing_method ? (list.pricing_method.name == 'Estimate' ? list.actual_time_used : list.estimated_time) : list.estimated_time
    let attachment = [];
    const [state, setState] = React.useState({
        attachments: [],
        comment: '',
        comments: [],
        comment_attachments: [],
        isSubmit: false,
        clearRich:false
    });


    useEffect(() => {
        console.log(props.comments)
        setState({...state, comments: props.comments.value })
    }, [props.comments.value])

    if (checkObject(list.attachments)) {
        console.log(list.attachments)
        console.log(attachment)
        attachment.push(list.attachments);
    }

    const convertToHourAndMinute = (time) => {
        var hms = time;   // your input string
        let n = parseFloat(hms).toFixed(2);    // get only the 2 decimal after .
        var a = n.toString().split('.'); // split it at the colons

        var m = Math.floor(((a[1]) * 60), 10) / 100;        //convert decimal to minutes
        var hDisplay = a[0] > 0 ? a[0] + (a[0] == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        return hDisplay + mDisplay
    }

    const downloadAll = (e) => {
        let attachmentList = attachment[0];
        for (let index = 0; index < attachmentList.length; index++) {
            saveFile(attachmentList[index]);
        }
    }

    const saveFile = (e) => {
        console.log('saveFile', e);
        let link = e.path;
        let title = e.filename;
        fileSaver.saveAs(
            link,
            title
        );
    }

    function handleSubmit(event) {
        const { comment, comments, attachments } = state;
        setState({ ...state, isSubmit: true });
        
        if (comment === '' && attachments.length == 0) {
            setState({ ...state, isSubmit: false, clearRich:true });
            Swal.fire({
                title: 'Error!',
                text: 'Please enter a comment!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            const data = new FormData();
            let files = attachments;
            data.append('task_id', list.id);
            data.append('body', comment);
            data.append('has_rich_text', false);

            if (files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    data.append('files[]', files[index]);
                }

            }else{
                data.append('files[]',[]);
            }
            
            Api.addComment(data).then(result => {
                // comments.unshift(result.data.comment_details);
                setState({ ...state, 
                    attachments: [],
                    comment_attachments: [],
                    // comments: comments,
                    comment: '',
                    isSubmit: false,
                    clearRich:true
                });
            }).catch(error => {
                console.log(error);
                setState({ ...state, isSubmit: false });
            });

        }
    }

    function handleImageChange(e) {
        e.preventDefault();
        let files = e.target.files;
        setState({ ...state, attachments: files })


    }

    let status = '';
    let forApproval = false;
    if (list.status) {
        if (list.status.id === 1) {
            status = '';
        } else if (list.status.id === 2) {
            status = 'circle-badge-proposal';
            forApproval = true;
        } else if (list.status.id === 3) {
            status = 'circle-badge-awaiting';
        } else if (list.status.id === 4) {
            status = 'circle-badge-inprogress';
        } else if (list.status.id === 5) {
            status = 'circle-badge-review';
        } else if (list.status.id === 6) {
            status = 'circle-badge-onhold';
        } else if (list.status.id === 7) {
            status = 'circle-badge-completed';
            forApproval = true;
        } else if (list.status.id === 8) {
            status = 'circle-badge-archive';
        } else if (list.status.id === 19) {
            status = 'circle-badge-awaiting';
            forApproval = true;
        }
    }

    const handleChangeRich = (e) => {
        console.log(e);
        setState({ ...state, comment: e, clearRich: false })
    }

    return (
        <React.Fragment>
            <input accept="image/*,application/pdf" style={{ display: 'none' }} id="icon-button-file" type="file" onChange={(e) => handleImageChange(e)} multiple />
            <div className="ticket-details" >
                <div className="card">
                    {
                        forApproval && (
                            <div className="card-alert flex-center">
                                <div>
                                    <img src={alertCircle} alt="" /><span>This task is pending for your approval.</span>
                                </div>
                            </div>
                        )

                    }
      
                    <div className="card-header">
                        <div className="flex-center">
                            <span className={`self-center circle-badge ${status}`} ></span>
                            <div className="">
                                <div className="text-black">{list.status && forApproval ? "For Approval" : list.status.status_title}</div>
                                <div className="text-grey text-small text-h-16">
                                    {
                                    list.status_id===2 ? "Job Scope Approval":
                                    list.status_id===7 ? "Go Live Approval":
                                    list.status_id===19 ? "Completion Approval":
                                    ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="close-button" >
                            {
                                list.status_id===2 ? <span onClick={props.approve} class={"action"}>✓ Approve Job Scope</span>:
                                list.status_id===7 ? <span onClick={props.approve} class={"action"}>✓ Approve Go Live</span>:
                                list.status_id===19 ? <span onClick={props.approve} class={"action"}>✓ Approve Completion</span>:
                                <span onClick={props.close} class={"view"} data-dismiss="modal" aria-label="Close"> view details</span>
                                // <button onClick={props.close} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            }

                            <span id="example" className="bell">
                                <Dropdown className="dropdown-bell" isOpen={props.isNotification} toggle={props.toggleNotification}>
                                <DropdownToggle className="remove-button-style" ><img src={notificationBell} alt="" /></DropdownToggle>

                                <DropdownMenu right>
                                    <div>
                                        <div className="notification-settings-header">Email Notifications</div>
                                        <div className="flex flex-col notification-settings-body">
                                            <div className="flex flex-row gap-12">
                                                <div>
                                                    <label className="custom-toggle custom-toggle-primary" >
                                                        <input type="checkbox" checked={props.notificationSettings.generalNotification} onChange={()=>props.changeNotificationSetting('generalNotification')}/>
                                                        <span className="custom-toggle-slider rounded-circle"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className="font-weight-bold">General Notifications</div>
                                                    <div>Notify me when my request has is being investigated before commencement,  work has started, and when your work is being tested.</div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-12">
                                                <div>
                                                    <label className="custom-toggle custom-toggle-primary">
                                                        <input type="checkbox" checked={props.notificationSettings.approvalNotification} onChange={()=>props.changeNotificationSetting('approvalNotification')} />
                                                        <span className="custom-toggle-slider rounded-circle"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className="font-weight-bold">For Approval Notifications</div>
                                                    <div>Notify me when my request scope is ready to confirm, Ready to Approve, and Ready to Deploy phases.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </DropdownMenu>
                                </Dropdown>
                                {/* <Popover placement="bottom-end" isOpen={props.isNotification} target="example" toggle={props.toggleNotification}>
                                    <div>Email Notifications</div>
                                    <PopoverBody>
                                        <div>General Notifications</div>
                                        <div>For Approval Notifications</div>
                                    </PopoverBody>
                                </Popover> */}
                                {/* <Dropdown isOpen={props.isNotification} toggle={props.toggleNotification} {...props} /> */}
                            </span>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="card-details" >
                            <div className="row" >

                                <div className="col-8" >
                                    <h5>{list.text}</h5>
                                    <p className="p-tag">{list.requestor ? `by ${list.requestor.surname} ${list.requestor.firstname}` : 'No Requestor'}</p>
                                </div>
                                <div className="col-4" >
                                    <h6 className="title-1" >TICKET NO.</h6>
                                    <h6 className="title-2" >{list.task_number}</h6>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-4" >
                                    <h6 className="title-1" >TIME</h6>
                                    <h6 className="title-2" >{convertToHourAndMinute(time)}</h6>
                                </div>
                                <div className="col-4" >
                                    <h6 className="title-1" >METHOD</h6>
                                    <h6 className="title-2" >{list.pricing_method ? list.pricing_method.name : ''}</h6>
                                </div>
                                <div className="col-4" >
                                    <h6 className="title-1" >EXPECTED COMPLETION</h6>
                                    <h6 className="title-2" >{list.due_date ? moment(list.due_date).format('MM-DD-YY,' + ' h:mm A') : 'No due date specified' }</h6>
                                </div>
                            </div>
                            {
                            list.clarified_description_to_client &&
                            <div className="mt-3 mb-3" >
                                {HtmlParser(list.clarified_description_to_client)}
                            </div>
                            }
                             <div className="mt-3" >
                                {HtmlParser(list.description)}
                            </div>
                            <div>
                            </div>
                            <table className="table-attachment table table-centered table-nowrap table">
                                <tbody>
                                    <tr>
                                        <td width="150" ><img src={iconAttachment} /> <span className="ml-2" >{attachment[0].length} attachments</span> </td>
                                        <td width="100">
                                            { attachment[0].length > 0 && <a className="download-link" onClick={downloadAll} href="#">Download all</a> }
                                        </td>
                                        <td ><div className="border-center"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="container" >
                                <div className="row attachment-list-row">
                                    {attachment[0].map((item, i) => {
                                        return (
                                            <div className="col-md-6" key={i} >
                                                <AttachmentFile data={item} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <h5 className="mt-5 mb-4" >Communication Thread</h5>
                            <div className="communication-theard" >
                                <div className="communication-image">
                                    <Avatar
                                        name={list.requestor ? `${getFirstWord(list.requestor.firstname)} ${getFirstWord(list.requestor.surname)}` : ''}
                                        round={true}
                                        size="48"
                                        color="#f44336"
                                        className="btn-cirle-avatar"
                                    />
                                </div>
                                <div className="communication-details">
                                    {/* <textarea className="form-control" rows="6" value={state.comment} onChange={(e) => { setState({ ...state, comment: e.target.value }) }} placeholder="Respond here..." ></textarea> */}
                                    <RichText
                                        handleChange={handleChangeRich} 
                                        clear={state.clearRich}
                                    />
                                    <div className="action-footer2" >
                                        <div className="row">
                                            <div className="col-md-8 attach-file"   >
                                                <img title="Click here to add attachment" src={iconAttachment} style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('icon-button-file').click(); }} /> <span>{state.attachments.length}</span> Attach File
                                            </div>
                                            <div className="col-md-4 d-flex justify-content-end">
                                                <button className="btn btn-send" disabled={state.isSubmit} onClick={handleSubmit} >
                                                    { state.isSubmit && <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i> }
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CommentSection 
                                comments={state.comments}
                                loading={props.comments.loading}
                            />

                            <div style={{ minHeight: 20 }}></div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}