import React from 'react';
import Avatar from 'react-avatar';


export  default   function AvatarDiv(props){
    return (
        <React.Fragment>
            <Avatar style={{ cursor: 'pointer'}}  name={props.name}  round={props.round} size={props.size}  color="#A6B0CF"  src={props.image}  />
        </React.Fragment>
    );
} 