import React from 'react';


export default function TextSelect(props) {
    // console.log(props.dataKey);
    var defaultProps = {};
    const [inputID, setInputValue] = React.useState('');
    const [list, setList] = React.useState([]);

    if (props.error) {
        defaultProps = {
            error: true,
            label: "text-danger",
            input: "is-error-input"
        };
    }

    let itemKey = '';
    React.useEffect(() => {
        if (props.isObject) {
            if (props.dataKey) {
                setInputValue(props.dataKey);
                setList(props.data);
            }
        } else {
            setList(props.data);
        }


    }, [props]);

    if (!props.isObject) {
        return (
            <div className="form-group  ">
                <label htmlFor="inputLabel" className={defaultProps.label} >{props.label}</label>
                <div className="input-group">
                    <select onChange={props.handleChange} name={props.name} type={props.type} value={props.value} placeholder={props.placeholder} className={`form-control ${defaultProps.input}`} >
                        <option >{props.placeholder}</option>
                        {
                            list.map((item, i) => {
                                return <option key={i}>{item}</option>
                            })
                        }
                    </select>
                </div>
                <span className={`help-block ${defaultProps.label}`} >{props.error}</span>
            </div>
        )

    }

    return (
        <div className="form-group  ">
            <label htmlFor="inputLabel" className={defaultProps.label} >{props.label}</label>
            <div className="input-group">
                <select onChange={props.handleChange} name={props.name} type={props.type} value={props.value} placeholder={props.placeholder} className={`form-control ${defaultProps.input}`} >
                    <option>{props.placeholder}</option>
                    {
                        list.map((item, i) => {
                            //console.log(item[inputID]);
                            return <option data-id={item.value} key={i}>{item[inputID]}</option>
                        })
                    }
                </select>
            </div>
            <span className={`help-block ${defaultProps.label}`} >{props.error}</span>
        </div>
    )

}