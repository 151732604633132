import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal, Card, FormGroup, Label, InputGroup } from "reactstrap";
import Dropzone from "react-dropzone";
// import DatePicker from "react-datepicker";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from 'moment';
//Import Image
// import calendarIcon from "../../assets/images/calendar-icon.png";
import uploadIcon from "../../assets/images/upload-icon.png";
import TextInput from "../../components/TextInput";
import ConfirmSend from "../../components/ConfirmSend";
import RichText from '../../components/RichText';
import {
    UniversalValidator
} from '../../Utils/helper';
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/ticket-notification.png";
import iconPDF from "../../assets/images/icon-pdf.png";
import Select from "react-select";

export default function AddTicket(props) {
    let currentDate = new Date();
    var date = new Date();
    date.setDate(date.getDate() + 1);
    const [state, setState] = React.useState({
        name: '',
        clientRequestTemplate: '',
        description: '',
        description2: '',
        dueDate: date,
        dueDateError: '',
        time: '06:00:00',
        selectedFiles: [],
        isSubmit: false,
        showNotification: false,
        clearRich: false,
        validFiles: [],
        website: null,
        websites: []
    });

    const maxUploadFileSize = 20971520;
    const [uploadSize, setUploadSize] = React.useState(0);

    React.useEffect(() => {
        clearFields();
    }, [props])

    function handleAcceptedFiles(files) { //console.log(files);
        //1 mb  = 1048576;
        let mb_25valid = 1048576 * 1;
        let validFiles = state.validFiles;
        let isValid = true;
        let temp = 0;
        const notUploaded = [];

        for (let index = 0; index < files.length; index++) {
            temp = uploadSize + files[index].size;
            if (temp >= maxUploadFileSize) {
                notUploaded.push({name: files[index].name, size: files[index].size});
            } else {
                let dataFiles = files[index];
                var fileType = files[index].type;
                let isImage = false;
                if (fileType.search('image') >= 0) {
                    isImage = true;
                }
                if (files[index].size > mb_25valid) {
                    isValid = false;
                }
                dataFiles.isValid = isValid;
                dataFiles.isImage = isImage;
                dataFiles.preview = URL.createObjectURL(files[index]);
                dataFiles.formattedSize = formatBytes(files[index].size);
                validFiles.push(dataFiles);
                setUploadSize(temp);
            }

            if(notUploaded.length) {
                props.showUploadError(`Total file size is larger than 20 MB`, notUploaded);
            }
        }

       setState({ ...state, validFiles: validFiles });
        // files.map(file =>
        //     Object.assign(file, {
        //         preview: URL.createObjectURL(file),
        //         formattedSize: this.formatBytes(file.size)
        //     })
        // );  
            //    console.log('validFiles', state.validFiles);
            // let olfiles = [];
            // olfiles.push(state.validFiles);
            // olfiles.push(validFiles);
            // console.log('olfiles', olfiles);
            // let allFiles = state.validFiles;
            // allFiles.push(validFiles);
            // console.log('allFiles',allFiles);
            //let oldFiles = validFiles;
        //setState({ ...state, validFiles: validFiles });

    };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    function handleSubmit(event) {
        event.preventDefault();
        let confirm_details = { page: 'new ticket' };

        ConfirmSend(confirm_details).then(res => {
            if (res.isConfirmed)
                return handleSendSubmission();
        })
    }

    const handleSendSubmission = () => {
        const nameError = UniversalValidator({ value: state.name, isValidate: true, isUrl: false, errorMsg: 'Ticket Name is required.' });

        let initialdate = moment(state.dueDate).format("YYYY-MM-DD");
        let start_time = state.time;
        let tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
        let datetimeA = moment(initialdate + " " + start_time);
        let selectDateTime = moment(datetimeA).format("YYYY-MM-DD");

        let dueDateError = '';
        if (selectDateTime < tomorrow) 
            return dueDateError = 'Please enter valid due date.';

        if (nameError || dueDateError) 
            return setState({ ...state, nameError: nameError, dueDateError: dueDateError });

        setState({ ...state, isSubmit: true });
        let duedate = moment(state.dueDate).format("YYYY-MM-DD");
        let files = state.validFiles;
        const data = new FormData();
        data.append('request_type', 'jiffi');
        if (files.length > 0) {
            console.log('files',files);
            for (let index = 0; index < files.length; index++) {
                data.append('name', state.name);
                data.append('description', state.description);
                data.append('due_date', duedate);
                data.append('time', state.time);
                data.append('files[]', files[index]); 
                data.append('project_id', props.project_id);
            }
        } else {
            data.append('name', state.name);
            data.append('description', state.description);
            data.append('due_date', duedate);
            data.append('time', state.time);
            data.append('project_id', props.project_id);
        }

        if(state.website) {
            data.append('property_id', state.website?.value ?? "");
        }
        
        saveTicketAPI(data);
    }

    const saveTicketAPI = (data) => {
        Api.saveTicket(data).then(result => {
            showTost();
            setState({ ...state, 
                isSubmit: false, 
                showNotification: true,
                clearRich: true,
                name: '',
                description: '',
                description2: '',
                dueDate: date,
                dueDateError: '',
                time: '06:00:00',
                validFiles: [],
            });
            props.new(result.data);
            props.hideModal();
        }).catch(error => {
            console.log(error);
            setState({ ...state, isSubmit: false });
        });
    }

    function onEditorStateChange(event) {
        setState({ ...state, description2: event });
        let editorState = event;
        let editorSourceHTML = draftToHtml(convertToRaw(event.getCurrentContent()))
        setState({ ...state, description: editorSourceHTML });
    }


    function handleChangeDescription(event) {
        let value = event.target.value;

        let blocksFromHtml = htmlToDraft(value);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        let editorState = EditorState.createWithContent(contentState);

        let editorState2 = editorState;
        let editorSourceHTML = value;

    }

    function clearFields() {
        setState({
            ...state,
            name: '',
            description: '',
            description2: '',
            dueDate: date,
            dueDateError: '',
            time: '06:00:00',
            selectedFiles: [],
            validFiles: [],
            isSubmit: false,
            showNotification: false,
        });
    }

    let errorDue = {};

    if (state.dueDateError) {
        errorDue = {
            error: true,
            label: "text-danger",
            input: "is-error-input"
        };
    }

    var x = document.getElementById("snackbar");
    function showTost() {
        // Get the snackbar DIV

        // Add the "show" class to DIV
        x.className = "show row notification-div";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () { closeToast(); }, 5000);
    }

    function closeToast() {
        x.className = x.className.replace("show", "");
    }

    const handleChangeRich = (e) => {
        setState({ ...state, description: e, clearRich: false });
    }

    const removeAttachment = (attachment, index) => {
        let attachments = state.validFiles;
        attachments.splice(index, 1);
        setState({ ...state, validFiles: attachments });
        setUploadSize(uploadSize - attachment.size)
    }

    const handleCancel = () => {
        clearFields();
        props.hideModal();
    }

    const closeModal = () => {
        setUploadSize(0);
        props.hideModal();
    }

    const handleWebsiteSelect = (e) => {
        setState({...state, website: e});
    }

    const handleClientRequestTemplateSelect = (e) => {
        if(e.value === state.clientRequestTemplate.value) {
            setState({...state, clientRequestTemplate: '', description: '', clearRich: false});
        } else {
            setState({...state, clientRequestTemplate: e, description: `<p>${e.content}</p>`, clearRich: false});
        }
    }

    return (
        <React.Fragment>
            <div id="snackbar" onClick={closeToast} className="display-none" >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>You successfully created a new ticket!</p>
                </div>
            </div>


            {/* {
                state.isSubmit?
                (<div id="cover-spin"></div>)
                :''
            } */}


            <Modal
                isOpen={props.open}
                toggle={closeModal}
                centered={true}
                size="xl"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Create New Ticket</h5>
                    <button
                        type="button"
                        onClick={closeModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <form>
                                <TextInput
                                    name="name"
                                    type="text"
                                    label="Ticket Name"
                                    placeholder="Enter Ticket Name"
                                    error={state.nameError}
                                    value={state.name}
                                    handleChange={event => setState({ ...state, name: event.target.value, nameError: '' })}
                                />

                                <Label>Website</Label>
                                <Select
                                    className="form-select mb-2"
                                    value={state.website}
                                    onChange={handleWebsiteSelect}
                                    options={props.websites}
                                    // placeholder="Select Website (OPTIONAL)"
                                    // components={{
                                    //     IndicatorSeparator: () => null
                                    // }}
                                />
                                {/* <div style={{ marginTop: -5, marginBottom: 5 }}><span className={`help-block error`} >{this.state.nameError}</span></div> */}

                                <Label>What do you need help with?</Label>
                                <Select
                                    className="form-select mb-2"
                                    value={state.clientRequestTemplate}
                                    onChange={handleClientRequestTemplateSelect}
                                    options={props.templates}
                                    placeholder="Select"
                                    // components={{
                                    //     IndicatorSeparator: () => null
                                    // }}
                                />

                                <Label>Description</Label>
                                <RichText
                                    value={state.description}
                                    handleChange={handleChangeRich}
                                    clear={state.clearRich}
                                />
                                {/* <Editor
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                                <textarea value={state.description} style={{ display: 'none' }}
                                    onChange={handleChangeDescription}
                                ></textarea> */}
                            </form>
                            {/* <div className="row mt-2">
                                <div className="col-lg-6">
                                    <FormGroup className="mb-4">
                                        <Label>Due Date</Label>
                                        <InputGroup>
                                            <DatePicker
                                                className={`form-control ${errorDue.input}`}
                                                selected={state.dueDate}
                                                //dateFormat="dd MMMM yyyy"
                                                onChange={event => setState({ ...state, dueDate: event })}
                                            />
                                        </InputGroup>
                                        <img src={calendarIcon} className="calendar-icon" />
                                        <span className={`help-block ${errorDue.label}`} >{state.dueDateError}</span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup className="mb-4">
                                        <Label>Time</Label>
                                        <input onChange={event => setState({ ...state, time: event.target.value })} className="form-control" type="time" defaultValue={state.time} id="example-time-input" />
                                    </FormGroup>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" >Attachment (Optional)</label>

                            <Dropzone
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <img src={uploadIcon} style={{ cursor: 'pointer' }} />
                                            </div>
                                            <h4>Drag file here or <span style={{ cursor: 'pointer' }} >browse</span></h4>
                                            <p>Max 20MB of PDF or image (jpeg, jpg, png) file can be uploaded.</p>
                                            <p>Uploaded {(uploadSize / 1048576).toFixed(2)} MB out of 20 MB</p>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                            >
                                {state.validFiles.map((f, i) => {
                                    return (
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                        >
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                        { f.isImage ? (
                                                            <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                            />
                                                        ) : ( 
                                                            <img src={iconPDF} /> 
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <Link to="#" className="text-muted font-weight-bold">
                                                            {f.name}
                                                        </Link>
                                                        <p className="mb-0">
                                                            <strong>{f.formattedSize}</strong>
                                                        </p>
                                                    </Col>
                                                    <Col className="remove-attachment">
                                                        <i className="fas fa-times" onClick={() => removeAttachment(f, i)}></i>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="px-12 mt-4">
                            <div className="weight-500 text-darkBlue text-16 py-15">Email Notifications</div>
                            <div className="flex flex-row notification-settings-body bt-light">
                                <div className="flex flex-row gap-12">
                                    <div>
                                        <label className="custom-toggle custom-toggle-primary" >
                                            <input type="checkbox" checked={props.notificationSettings.generalNotification} onChange={()=>props.changeNotificationSetting('generalNotification')}/>
                                            <span className="custom-toggle-slider rounded-circle"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <div className="title weight-500 text-16">General Notifications</div>
                                        <div>Notify me when my request has is being investigated before commencement,  work has started, and when your work is being tested.</div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-12">
                                    <div>
                                        <label className="custom-toggle custom-toggle-primary">
                                            <input type="checkbox" checked={props.notificationSettings.approvalNotification} onChange={()=>props.changeNotificationSetting('approvalNotification')} />
                                            <span className="custom-toggle-slider rounded-circle"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <div className="title weight-500 text-16">For Approval Notifications</div>
                                        <div>Notify me when my request scope is ready to confirm, Ready to Approve, and Ready to Deploy phases.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 bt-light">
                            <div className="text-right mt-4">
                                <button onClick={() => handleCancel()} type="button" className="btn btn-light waves-effect waves-light">Cancel</button> &nbsp;
                                <button disabled={state.isSubmit} type="button" onClick={handleSubmit} className="btn btn-primary waves-effect waves-light">
                                    {
                                        state.isSubmit ?
                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                            : ''
                                    }
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}