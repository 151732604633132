import React from 'react';
//Import Image
import noCard from "../assets/images/noCard.png";
import visaIcon from "../assets/images/icon-visa.png";
import americanExpress from "../assets/images/american-express.png";
import masterCard from "../assets/images/master-card.png";
import discoverCard from "../assets/images/discover-card.png";
import JCBCARD from "../assets/images/JCBCARD.png";
import deleteIcon from "../assets/images/icon-delete.png";
import addCardIcon from "../assets/images/icon-new-card.png";



const CardImage = (props) => {
    let image = '';
    if(props.item === 'Visa'){
        image = visaIcon;
    }else if(props.item === 'American Express'){
        image = americanExpress;
    }else if(props.item === 'MasterCard'){
        image = masterCard;
    }else if(props.item === 'JCB'){
        image = JCBCARD;
    }else if(props.item === 'discover'){
        image = discoverCard;
    }else{
        image = noCard;
    }
    return(
        <img src={image} />
    ) 
}


export default function SmallCreditCard(props) {
    let item = props.list;  
    
    const selectPayment = (e) => {
        props.action(item.id);
    }
    
    return (
        <React.Fragment>
            <div  className={`small-card-list ripple ${item.check ? 'active-checkbox' :''}`}  onClick={selectPayment}>
                <div className="row-1">
                   <CardImage item={item.brand} />
                </div>
                <div className="row-2">
                    <div className="card-number">****  ****  ****  {item.last_4_digits}</div>
                    <div className="date">VALID THRU {item.exp_month}/{item.exp_year}</div>
                </div>
                <div className="row-3">
                    <div className={`checkbox`}  >
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}