import axios from "axios";
import { Api, ApiAdmin } from './consts';
import {
    getCookie
} from './helper';
let token = getCookie("access_token");



const APIData = axios.create({
    baseURL: Api
});

export default class API {

    static login(parameters = {}) {
        return APIData.post(`login`,
            {
                email: parameters.username,
                password: parameters.password,
                remember: parameters.rememberIsChecked
            }
        );
    }

    static completeAccount(parameters = {}) {
        return APIData.post(`user/account/complete`,
            {
                email: parameters.email,
                password: parameters.password,
                token: parameters.token
            }
        );
    }

    static signupInvite(parameters = {}) {
        return APIData.post(`user/account/signup`,
            {
                firstname: parameters.firstname,
                lastname: parameters.lastname,
                email: parameters.email,
                password: parameters.password,
                token: parameters.token
            }
        );
    }

    static goAhead(parameters = {}) {
        return APIData.post(`go-ahead`,
            {
                token: parameters
            }
        );
    }

    static goLive(parameters = {}) {
        return APIData.post(`go-live`,
            {
                token: parameters
            }
        );
    }

    static noThankyou(parameters = {}) {
        return APIData.post(`no-thanks`,
            {
                token: parameters
            }
        );
    }

    static jobComplete(parameters = {}) {
        return APIData.post(`job-complete`,
            {
                token: parameters
            }
        );
    }

    static signup(parameters = {}) {
        return APIData.post(`user/account/signup`,
            {
                email: parameters.email,
                password: parameters.password,
                firstname: parameters.firstname,
                lastname: parameters.lastname,
                token: parameters.token
            }
        );
    }

    static saveTicket(parameters = {}) {
        // return APIData.post(`task`,
        //     {parameters
        //     },{
        //         'Content-Type': 'multipart/form-data',
        //         headers: {'Authorization': `Bearer  ${token}`,}
        //     }
        // );
        return APIData({
            method: 'post',
            url: 'task',
            data: parameters,
            headers: {
                'Authorization': `Bearer  ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static approveTicket(parameters = {}) {
        // return APIData.post(`task`,
        //     {parameters
        //     },{
        //         'Content-Type': 'multipart/form-data',
        //         headers: {'Authorization': `Bearer  ${token}`,}
        //     }
        // );
        console.log(parameters);
        return APIData({
            method: 'post',
            url: 'task/update/status',
            data: parameters,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static updateAttachment(parameters = {}) {
        return APIData({
            method: 'post',
            url: 'task/merge-annotation',
            data: parameters,
            headers: {
                'Authorization': `Bearer  ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static getTicket(parameters = {}) {
        return APIData({
            method: 'get',
            url: `task/${parameters}/tasks`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static getCard(parameters = {}) {
        return APIData({
            method: 'get',
            url: `company/${parameters}/card/list`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }


    static addCard(parameters = {}) {
        return APIData.post(`company/card/add`,
            {
                company_id: parameters.company_id,
                card_number: parameters.card_number,
                exp_month: parameters.exp_month,
                exp_year: parameters.exp_year,
                owner: parameters.owner,
                cvc: parameters.cvv,
                firstname: parameters.firstname,
                lastname: parameters.lastname,
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    static deleteCard(parameters = {}) {
        return APIData.post(`company/card/remove`,
            {
                company_id: parameters.company_id,
                card_id: parameters.card_id
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    static addUser(parameters = {}) {
        return APIData.post(`user`,
            {
                company_id: parameters.company_id,
                users: parameters.users,
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    static editUser(parameters = {}) {
        return APIData.post(`profile`,
            {
                id: parameters.user_details.id,
                email: parameters.user_details.email,
                firstname: parameters.user_details.firstname,
                lastname: parameters.user_details.surname,
                phone: parameters.user_details.phone
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    static updateSetting(settings) {
        return APIData.put(`settings`,
            {
                settings: settings,
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    static getSetting() {
        return APIData({
            method: 'get',
            url: `settings`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static changeUserStatus(parameters = {}) {
        return APIData.post(`user/toggle-status`,
            {
                id: parameters.id,
            }, {
            headers: { 'Authorization': `Bearer  ${token}`, }
        }
        );
    }

    

    static getOptions(parameters = {}) {
        return APIData({
            method: 'get',
            url: `stripe/skynet/plans`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static rechargeBalance(parameters = {}) {
        return APIData({
            method: 'post',
            url: `stripe/pay`,
            data: {
                card_id: parameters.card_id,
                plan_id: parameters.plan_id,
                hours: parameters.hours,
                code: parameters.code
            },
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static getCredit(parameters = {}) {
        return APIData({
            method: 'get',
            url: `company/${parameters}/credits`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static getUser(parameters = {}) {
        return APIData({
            method: 'get',
            url: `user`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static applyCode(parameters = {}) {
        return APIData({
            method: 'get',
            url: `code/apply`, 
            params: {
                hours: parameters.hours,
                credits: parameters.credits,
                amount: parameters.amount,
                code: parameters.code,
                normal_amount: parameters.normal_amount
            },
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static companyInvoices(parameters = {}) {
        return APIData({
            method: 'get',
            url: `company/${parameters.company_id}/${parameters.is_subscription}/${parameters.is_transaction}/invoices`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static addComment(parameters = {}) {
        return APIData({
            method: 'post',
            url: 'task/comment',
            data: parameters,
            headers: {
                'Authorization': `Bearer  ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static getComment(parameters = {}) {
        return APIData({
            method: 'get',
            url: `task/${parameters}/comments`,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static loginAsContact(parameters = {}) {
        return APIData.post(`login-as-contact`,
            {
                token: parameters,
            }
        );
    }

    static getCommissions(parameters = {}) {
        const url = `commissions/${parameters.company_id}/${parameters.query}`

        return APIData({
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer  ${token}`,
            }
        });
    }

    static forgotPasswordEmail(email = '') {
        return APIData.post(`send-reset-password-email`, {
            email: email,
        });
    }

    static resetPassword(parameters = {}) {
        return APIData.post(`reset-password`,
            {
                email: parameters.email,
                password: parameters.password,
                token: parameters.token
            }
        );
    }

    static getWebsiteOptions() {
        const url = `website-options`;

        return APIData({
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    }

    static getTemplateOptions() {
        const url = `template-options`;

        return APIData({
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    }
};