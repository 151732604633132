import React from 'react';
import Swal from 'sweetalert2';
import { Alert} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import queryString from 'query-string';

// import images
import emailIco from "../../assets/images/complete-account-email-icon.png";
import logo from "../../assets/images/jiffi-logo.png";
import iconMail from "../../assets/images/icon-mail.png";
import iconCall from "../../assets/images/icon-call.png";
import TextInput from "../../components/TextInput";

import {
    emailValidator,
    UniversalValidator,
    passwordValidator2,
    setCookie
} from '../../Utils/helper';
import Api from "../../Utils/API";

function ForgotPassword(props) {
    let params = queryString.parse(props.location.search);
    const [state, setState] = React.useState({
        username: '',
        usernamError: '',
        password: '',
        passwordError: '',
        password2: '',
        passwordError2: '',
        passwordType: 'password',
        passwordIcon: "fa fa-eye",
        passwordType2: 'password',
        passwordIcon2: "fa fa-eye",
        errorLogin: false,
        isSubmit: false,
        errorText: '',
        email: params.email,
        token: params.token
    });

    const tooglePassword = () => {
        if (state.passwordType === 'password') {
            setState({ ...state, passwordType: 'text', passwordIcon: "fa fa-eye-slash" });
        } else {
            setState({ ...state, passwordType: 'password', passwordIcon: "fa fa-eye" });
        }
    }

    const tooglePassword2 = () => {
        if (state.passwordType2 === 'password') {
            setState({ ...state, passwordType2: 'text', passwordIcon2: "fa fa-eye-slash" });
        } else {
            setState({ ...state, passwordType2: 'password', passwordIcon2: "fa fa-eye" });
        }
    }

    function handleEmailSubmit(event) {
        event.preventDefault();
        const error = emailValidator(state.email);

        if(error) {
            setState({emailError: error});
        } else {
            setState({ ...state, emailError: false, isSubmit: true });
            Api.forgotPasswordEmail(state.email).then(result =>{
                setState({ ...state, isSubmit: false, emailError: false });
                Swal.fire({
                    title: '',
                    text: 'A reset password email has been sent to your inbox.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }).catch(error => {
                let message = error?.response?.data ?? 'An unexpected error has been encountered!'
                setState({ ...state, emailError: message, isSubmit: false });
                console.log(error);
            });
        }
    }

    function handlePasswordSubmit(event) {
        event.preventDefault();
        const { password, password2 } = state;
        const passworError = UniversalValidator({ value: password, isValidate: true, isUrl: false, errorMsg: 'Please enter password.' });
        let passwordError2 = passwordValidator2([password, password2]);
        if (passworError || passwordError2) {
            setState({ ...state, passwordError: passworError, passwordError2 : passwordError2 });
        } else {
            setState({ ...state, isSubmit: !state.isSubmit });
            let params = {
                password: state.password,
                email: state.email,
                token: state.token
            } 
            console.log(params);
            Api.resetPassword(params).then(result =>{
                setCookie(["access_token", result.data.access_token]);
                setCookie(["projects", JSON.stringify(result.data.projects)]);
                setCookie(["userDetails", JSON.stringify(result.data.user)]);
                Swal.fire(
                        'Success!',
                        'Password has been changed.',
                        'success'
                    )
                setTimeout( ()=>{
                    window.location.href = "/ticket";
                },2000);
            }).catch(error =>{
                let errorText = `Something wen't wrong!`;
                if(error.response){
                    errorText = error.response.data;
                }
                setState({ ...state, errorLogin: true, isSubmit: false, errorText: errorText });
            });
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid container-login">
                <div className="row row-login" >
                    <div className="col-md-6 login-left ">
                        {params.token ?
                            <div className="form-login" >
                                <div className="logo-div"> <img src={logo} /></div>
                                <div className="login-text">Complete Account</div>
                                <div className="complete-account-user-email"><img src={emailIco} /> {state.email ? state.email : 'sample@email.com'}</div>
                                {
                                    state.errorLogin?
                                    (
                                    <Alert color="danger" role="alert">
                                        {state.errorText}
                                    </Alert>
                                    )
                                    :''
                                }
                                <form onSubmit={handlePasswordSubmit}>
                                    <TextInput
                                        name="password"
                                        type={state.passwordType}
                                        label="Password"
                                        placeholder="Enter password"
                                        error={state.passwordError}
                                        value={state.password}
                                        handleChange={event => setState({ ...state, password: event.target.value, passwordError: '' })}
                                        icon={state.passwordIcon}
                                        tooglePassword={tooglePassword}
                                        isPassword={true}
                                    />

                                    <TextInput
                                        name="password2"
                                        type={state.passwordType2}
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        error={state.passwordError2}
                                        value={state.password2}
                                        handleChange={event => setState({ ...state, password2: event.target.value, passwordError2: '' })}
                                        icon={state.passwordIcon2}
                                        tooglePassword={tooglePassword2}
                                        isPassword={true}
                                    />
                                
                                    <div className="mt-3">
                                    <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light btn-login" type="submit">
                                            Submit 
                                            {
                                                state.isSubmit?
                                                (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                :''
                                            }
                                        </button>
                                    </div>
                                </form>

                                {/* LINKS */}
                                <div className="complete-account-home-link">
                                    <Link to="/login" className="back-home">{'<   Go to Home'}</Link>
                                </div>
                                <div className="text-center d-lg-none complete-account-links">
                                    <div>
                                        <img className="icon-footer" src={iconMail} /> support@jiffiwebhelp.com
                                    </div>
                                    <div>
                                        <img className="icon-footer-call" src={iconCall} /> 1300 247 516
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="form-login" >
                                <div className="logo-div"> <img src={logo} /></div>
                                <div className="login-text">Forgot Password</div>
                                <form onSubmit={handleEmailSubmit}>
                                    <TextInput
                                        name="email"
                                        type="text"
                                        label="Email"
                                        placeholder="Enter your registered email address"
                                        error={state.emailError}
                                        value={state.email ?? ''}
                                        handleChange={event => setState({ ...state, email: event.target.value, emailError: '' })}
                                        icon={state.emailIco}
                                        isPassword={false}
                                    />
                                
                                    <div className="mt-3">
                                    <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light btn-login" type="submit">
                                            Reset  Password 
                                            {
                                                state.isSubmit?
                                                (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                :''
                                            }
                                        </button>
                                    </div>
                                </form>

                                {/* LINKS */}
                                <div className="complete-account-home-link">
                                    <Link to="/login" className="back-home">{'<   Go to Home'}</Link>
                                </div>
                                <div className="text-center d-lg-none complete-account-links">
                                    <div>
                                        <img className="icon-footer" src={iconMail} /> support@jiffiwebhelp.com
                                    </div>
                                    <div>
                                        <img className="icon-footer-call" src={iconCall} /> 1300 247 516
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-md-6 login-right d-none d-md-block nopadding ">
                        <div className="bg-image"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(ForgotPassword);