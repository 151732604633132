import React from "react";
import Swal from 'sweetalert2'
import { Modal } from "reactstrap";
import TextInput from "../../components/TextInput";
import {
    handleErrors
} from '../../Utils/handleError';
import {
    getCookie
} from '../../Utils/helper';
import imageIcon from "../../assets/images/ticket-notification.png";
import plusIcon from "../../assets/images/plus-icon.png";
import {
    UniversalValidator
} from '../../Utils/helper';
import Api from "../../Utils/API";
import { useEffect } from "react";

function CreateUI(props) {
    return (
        <div>
            <div className="row-1">
                <TextInput
                    type="text"
                    label="Email Address"
                    placeholder="eg. name@email.com"
                    handleChange={props.handleChange1}
                    value={props.user.email || ''}
                />
            </div>
            <div className="row-1">
                <TextInput
                    type="text"
                    label="First Name"
                    placeholder="eg. John"
                    handleChange={props.handleChange}
                    value={props.user.firstname || ''}
                />
            </div>
            <div className="row-1">
                <TextInput
                    type="text"
                    label="Last Name"
                    placeholder="eg. Doe"
                    handleChange={props.handleChange2}
                    value={props.user.surname || ''}
                />
            </div>
            <div className="row-1">
                <TextInput
                    type="text"
                    label="Phone Number"
                    placeholder="eg. 02 1234 5678"
                    handleChange={props.handleChange3}
                    value={props.user.phone || ''}
                />
            </div>
        </div>
    )
}

export default function EditUser(props) {
    const [state, setState] = React.useState({
        isSubmit: false,
        id: 0,
        email: '',
        firstname: '', 
        surname: '', 
        phone: '',
    });

    useEffect(() => {
        setState({
            ...state, 
            id: props.userDetails.id,
            email: props.userDetails.email,
            firstname: props.userDetails.firstname,
            surname: props.userDetails.surname,
            phone: props.userDetails.phone,
        })
    }, [props]);

    function handleSubmit(event) {
        setState({ ...state, isSubmit: true });
        let error = false;
        let user_details = {
            id: state.id,
            email: state.email,
            firstname: state.firstname,
            surname: state.surname,
            phone: state.phone
        }
        let error_fields = {
            errorEmail: !user_details.email ? `Email ${ !user_details.firstname || !user_details.surname ? ',' : ''}` : '',
            errorFirstname: !user_details.firstname ? `First Name ${ user_details.surname ? '' : ','}` : '',
            errorSurname: !user_details.surname ? 'Last Name' : '',
        }

        if (!user_details.email || !user_details.firstname || !user_details.surname ) {
            setState({ ...state, isSubmit: false });
            error = true;
        }else{
            error = false;
        }

        if (error) {
            Swal.fire({
                title: 'Error!',
                text: `Please fill up the input field(s) [${error_fields.errorEmail} ${error_fields.errorFirstname} ${error_fields.errorSurname}]!`,
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            let params = { user_details }
            Api.editUser(params).then(result => {
                props.hideModal();
                setState({ ...state, isSubmit: false });
                showToast();
                props.updateSave();
            }).catch(error => {
                handleErrors();
                setState({ ...state, isSubmit: false });
            });
        }

    }

    function handleChange(event) {
        event.preventDefault();
        event.stopPropagation();
        setState({...state, firstname: event.target.value });
    }

    function handleChange2(event) {
        event.preventDefault();
        event.stopPropagation();
        setState({...state, surname: event.target.value });
    }

    function handleChange1(event) {
        event.preventDefault();
        event.stopPropagation();
        setState({...state, email: event.target.value });
    }

    function handleChange3(event) {
        event.preventDefault();
        event.stopPropagation();
        setState({...state, phone: event.target.value });
    }

    var x = document.getElementById("snackbar-userrr");

    function showToast() {
        x.className = "show row notification-div";
        setTimeout(function () { closeToast(); }, 5000);
    }

    function closeToast() {
        x.className = x.className.replace("show", "");
    }

    return (
        <React.Fragment>
            <div id="snackbar-userrr" onClick={closeToast} className="display-none" >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>You successfully updated the user!</p>
                </div>
            </div>
            <Modal
                isOpen={props.open}
                toggle={props.hideModal}
                centered={true}
                size="lg"
                style={{maxWidth: '1600px', width: '30%'}}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Edit User</h5>
                    <button
                        type="button"
                        onClick={props.hideModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <CreateUI
                        user={{
                            email: state.email,
                            firstname: state.firstname,
                            surname: state.surname,
                            phone: state.phone
                        }}
                        handleChange={handleChange}
                        handleChange3={handleChange3}
                        handleChange2={handleChange2}
                        handleChange1={handleChange1}
                    />
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <div className="text-left">
                                <button type="button" onClick={props.hideModal} className="btn btn-light waves-effect waves-orange">
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-right">
                                <button type="button" onClick={handleSubmit} className="btn btn-primary waves-effect waves-light " disabled={state.isSubmit} >
                                    { state.isSubmit ?
                                        <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>
                                        : ''
                                    }
                                        Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}