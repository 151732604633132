
let randomColor = ['#f44336', '#e91e63', '#9c27b0' , '#673ab7',  '#2196f3', '#009688', '#4caf50', '#8bc34a', '#ff9800', '#fbe9e7', '#556ee6', '#564ab1', '#6f42c1', '#f46a6a', '#34c38f', '#50a5f1'  ];

export const setCookie = data => {
    var d = new Date();
    d.setDate(d.getDate() + data[2]);
    var expires = "expires=" + d.toUTCString();
    document.cookie = data[0] + "=" + data[1] + ";" + expires + ";path=/";
};

export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const deleteCookie = data => {
    document.cookie = data + "= ; expires=Thu, 01 Jan 1970 00:00:00 UTC";
};

export const stringLimit = data => {
    var text = data.title;
    var count = data.count;
    var result = text.slice(0, count) + (text.length > count ? "..." : "");
    return result;
};


export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};


export const UniversalValidator = data => {
    if (!data.isValidate || data.value.length <= 0) return data.errorMsg;
    return '';
};


export const checkObject = data => {

    if (data) {
        return true;
    }
    return false;
};

export const minTommss = minutes => {

    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
};

export const getColor = data => {
    if(randomColor[data]){
        return randomColor[data];
    }
    //Math.floor(Math.random()*16777215).toString(16)
   return "#fbaf42";
};


export const passwordValidator2 = password => {
    console.log(password[0]);
    if (!password[1] || password[1].length <= 0) return 'Confirm Password cannot be empty.';
    if (password[0] !== password[1]) return 'Confirm Password mismatch.'

    return '';
};


export const pluralize = (count, noun, suffix = 's') => {
    return `${count} ${noun}${count > 1 ? suffix : ''}`;;
};

export const emailValidator = email => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty.';
    if (!re.test(email)) return 'Please enter a valid email address.';

    return '';
};

export const getFirstWord = data => {
    var firstWord = data.replace(/ .*/,'');
   return firstWord;
};






