
import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    getCookie
} from '../../Utils/helper';
import {
    handleErrors
} from '../../Utils/handleError';
import Api from "../../Utils/API";
import AddCard from "./AddCard";
import ModalGeneral from "./ModalGeneral";
import LoadingIndicator from "../../components/LoadingIndicator";


//Import Image
import noCard from "../../assets/images/noCard.png";
import visaIcon from "../../assets/images/icon-visa.png";
import americanExpress from "../../assets/images/american-express.png";
import masterCard from "../../assets/images/master-card.png";
import discoverCard from "../../assets/images/discover-card.png";
import JCBCARD from "../../assets/images/JCBCARD.png";
import deleteIcon from "../../assets/images/icon-delete.png";
import addCardIcon from "../../assets/images/icon-new-card.png";

let userDetails = getCookie("userDetails");
let loginUser = {};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}

const LoadingDiv = () => {
    return (
        <div className="w-400 justify-content-center"  >
            <div className="card-div" >
                <ul className="card-flex-container">
                    <li className="flex-item">
                        <Skeleton height={60} count={1} />
                    </li>
                    <li className="flex-item text-right">
                        <button className="btn-icon-only" >
                            <Skeleton height={30} width={30} count={1} />
                        </button>
                    </li>
                </ul>
                <div className="card-center-content" >
                    <Skeleton height={30} count={1} />
                </div>
                <ul className="card-flex-container">
                    <li className="flex-item card-name">
                        <Skeleton height={15} width={100} count={1} />
                    </li>
                    <li className="flex-item text-right valid ">
                        <Skeleton height={15} width={100} count={1} />
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CardImage = (props) => {
    let image = '';
    if(props.item === 'Visa'){
        image = visaIcon;
    }else if(props.item === 'American Express'){
        image = americanExpress;
    }else if(props.item === 'MasterCard'){
        image = masterCard;
    }else if(props.item === 'JCB'){
        image = JCBCARD;
    }else if(props.item === 'discover'){
        image = discoverCard;
    }else{
        image = noCard;
    }
    return(
        <img src={image} />
    ) 
}
class Card_management extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            newTicket: false,
            confirmDelete: false,
            customActiveTab: '1',
            selectedFiles: [],
            defaultDate: new Date(),
            list: [],
            company_id: loginUser.company ? loginUser.company.id : '',
            isDelete: false,
            cardId: '',
           

        };
        this.deleteCard = this.deleteCard.bind(this);
        this.getList = this.getList.bind(this);
        this.afterDeleteCard = this.afterDeleteCard.bind(this);

    }


    componentDidMount() {
        this.getList();
    }

    getList() {
        this.setState({ loading: true });
        Api.getCard(this.state.company_id).then(result => {
            setTimeout(() =>{
                this.setState({ list: result.data, confirmDelete: false, loading: false });
            }, 1000);
        }).catch(error => {
            //handleErrors();
        });
    }


    deleteCard(e) {


        this.setState({ confirmDelete: true, card_id: e.target.getAttribute("card_id") });
    }

    afterDeleteCard() {
        this.setState({ isDelete: true });
        let params = {
            company_id: this.state.company_id,
            card_id: this.state.card_id,
        }
        Api.deleteCard(params).then(result => {
            this.getList();
            this.setState({ isDelete: false });
        }).catch(error => {
            handleErrors();
            this.setState({ isDelete: false });
        });
    }

    addCard(){
        this.setState({ newTicket: false });
        this.getList();
    }

    render() {
        console.log('list', this.state.list);
        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                {
                    this.state.isDelete ?
                        (<div id="cover-spin"></div>)
                        : ''
                }
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-title-box">
                                    <h2>Linked Account  </h2>
                                    <div className="circle">
                                        <span>{this.state.list.length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-card ">
                            
                            {this.state.list.map((item, i) => {
                                return (
                                    <div className="w-400 justify-content-center" key={i} >
                                        <div className="card-div" >
                                            <ul className="card-flex-container">
                                                <li className="flex-item">
                                                    <CardImage item={item.brand} />
                                                </li>
                                                <li className="flex-item text-right">
                                                    <button className="btn-icon-only ripple" >
                                                        <img src={deleteIcon} card_id={item.id} onClick={this.deleteCard} />
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="card-center-content" >
                                                ****  ****  ****  {item.last_4_digits}
                                            </div>
                                            <ul className="card-flex-container">
                                                <li className="flex-item card-name">
                                                    {item.name}
                                                </li>
                                                <li className="flex-item text-right valid ">
                                                    VALID THRU {item.exp_month}/{item.exp_year}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                            {this.state.loading ? <LoadingDiv /> : ''}
                            <div className="w-400" onClick={() => { this.setState({ newTicket: !this.state.newTicket }) }} >
                                <div className="new-card-div ripple"  >
                                    <img src={addCardIcon} />
                                    <span>Add New Card</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddCard
                    open={this.state.newTicket}
                    hideModal={event => this.setState({ newTicket: false })}
                    addCard={this.addCard.bind(this)}
                />
                <ModalGeneral
                    open={this.state.confirmDelete}
                    hideModal={event => this.setState({ confirmDelete: false })}
                    action={this.afterDeleteCard}
                />
            </React.Fragment>
        );
    }
}

export default Card_management;
