import React from "react";
import Swal from 'sweetalert2'
import { Modal } from "reactstrap";
import TextInput from "../../components/TextInput";
import {
    handleErrors
} from '../../Utils/handleError';
import {
    getCookie
} from '../../Utils/helper';
import imageIcon from "../../assets/images/ticket-notification.png";
import plusIcon from "../../assets/images/plus-icon.png";
import {
    UniversalValidator
} from '../../Utils/helper';
import Api from "../../Utils/API";

let userDetails = getCookie("userDetails");
let loginUser = {};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}


function CreateUI(props) {
    return props.users.map((el, i) =>
        <div className="row" key={i} >
            <div className="col-lg-4">
                <TextInput
                    name={i}
                    type="text"
                    label="Email"
                    placeholder="Email"
                    handleChange={props.handleChange1}
                    value={el.email || ''}
                />
            </div>
            <div className="col-lg-4">
                <TextInput
                    name={i}
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    handleChange={props.handleChange}
                    value={el.firstname || ''}
                />
            </div>
            <div className="col-lg-4">
                <TextInput
                    name={i}
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    handleChange={props.handleChange2}
                    value={el.lastname || ''}
                />
            </div>
        </div>
    )
}





export default function AddUser(props) {

    const year = (new Date()).getFullYear();
    let years = Array.from(new Array(20), (val, index) => index + year);
    let months = [
        { value: '01', month: 'January' },
        { value: '02', month: 'Febuary' },
        { value: '03', month: 'March' },
        { value: '04', month: 'April' },
        { value: '05', month: 'May' },
        { value: '06', month: 'June' },
        { value: '07', month: 'July' },
        { value: '08', month: 'August' },
        { value: '09', month: 'September' },
        { value: '10', month: 'October' },
        { value: '11', month: 'November' },
        { value: '12', month: 'December' },
    ]
        ;

    const [state, setState] = React.useState({
        isSubmit: false,
        month: '',
        isCoppied: false,
        monthError: '',
        users: [
            { email: '', firstname: '', lastname: '' }
        ],

    });


    function handleSubmit(event) {
        let users = state.users;

        setState({ ...state, isSubmit: true });

        let error = 0;
        for (let index = 0; index < users.length; index++) {
            if (!users[index].email || !users[index].firstname || !users[index].lastname) {
                error++;
            }

        }

        if (error > 0) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill up all  input fields!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            var myJSONUser = JSON.stringify(users);
            let params = {
                company_id: loginUser.company ? loginUser.company.id : '',
                users: myJSONUser,
            }

            Api.addUser(params).then(result => {
                setState({
                    isSubmit: false,
                    month: '',
                    monthError: '',
                    users: [
                        { email: '', firstname: '', lastname: '' }
                    ],

                });
                showTost();
                props.newSave();
            }).catch(error => {
                handleErrors();
                setState({ ...state, isSubmit: false });
            });
        }

    }

    function creditCard(value) {
        let values = value.target.value;
        if (values.length < 20) {
            setState({ ...state, card: cc_format(value.target.value) });
        }

    }

    function cc_format(value) {
        const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
        const onlyNumbers = value.replace(/[^\d]/g, '')

        return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
            [$1, $2, $3, $4].filter(group => !!group).join(' ')
        )
    }

    function cvvChange(value) {
        let values = value.target.value;
        if (values.length < 5) {
            setState({ ...state, cvv: values });
        }

    }

    function numberOnly(value) {
        return value.replace(/[^\d]/g, '');

    }

    function changeMonth(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-id');
        setState({ ...state, month: e.target.value, monthData: option });
    }



    function handleChange(event) {
        let values = [...state.users];
        values[event.target.getAttribute('name')]['firstname'] = event.target.value;
        setState({ users: values });

    }

    function handleChange2(event) {
        let values = [...state.users];
        values[event.target.getAttribute('name')]['lastname'] = event.target.value;
        setState({ users: values });

    }

    function handleChange1(event) {
        let values = [...state.users];
        values[event.target.getAttribute('name')]['email'] = event.target.value;
        setState({ users: values });

    }


    function addUser() {
        let values = [...state.users];
        values.push({ email: '', firstname: '', lastname: '' });
        setState({ users: values });
    }


    var x = document.getElementById("snackbar-userr");

    function showTost() {
        x.className = "show row notification-div";
        setTimeout(function () { closeToast(); }, 5000);
    }

    function closeToast() {
        x.className = x.className.replace("show", "");
    }

    function handleChangeInvite(){

    }

    function copyLink(){
        var textField = document.createElement('textarea')
        textField.innerText = props.inviteLink;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setState({...state, isCoppied: true});
    }

    






    return (
        <React.Fragment>
            <div id="snackbar-userr" onClick={closeToast} className="display-none" >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>You successfully created a new user!</p>
                </div>
            </div>
            <div id="snackbar-users" onClick={closeToast} className="display-none" >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>Card successfully linked!</p>
                </div>
            </div>
            <Modal
                isOpen={props.open}
                toggle={props.hideModal}
                centered={true}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Add New User</h5>
                    <button
                        type="button"
                        onClick={props.hideModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="form-group  ">
                                <label htmlFor="inputLabel"  >Invite Share Link</label>
                                <div className="input-group">
                                    <input onChange={handleChangeInvite}  type="text" value={props.inviteLink}  className={`form-control`} disabled={true} />
                                </div>
                                {
                                    state.isCoppied ?
                                    <span className={`help-block`} >Share Link successfully copied!</span>
                                    :''
                                }
                            </div>
                            {/* <TextInput
                                name="card"
                                type="text"
                                label="Invite Share Link"
                                placeholder="Invite Share Link"
                                value={props.inviteLink}
                                handleChange={}
                            /> */}
                        </div>
                        <div className="col-sm-3" style={{ display: 'flex', alignItems: 'center' }} >
                            <button onClick={copyLink} className="btn btn-outline btn-primary" > <i className="fa fa-link"></i> Copy Link</button>
                        </div>
                    </div>
                    <div className="or-div" >
                        <table>
                            <tbody>
                                <tr>
                                    <td className="td-1">
                                        <div></div>
                                    </td>
                                    <td className="td-2">or</td>
                                    <td className="td-3">
                                        <div></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CreateUI
                        users={state.users}
                        handleChange={handleChange}
                        handleChange2={handleChange2}
                        handleChange1={handleChange1}
                    />
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <div className="text-left">
                                <button type="button" onClick={addUser} className="btn btn-default btn-rounded waves-effect waves-light btn-users" > <img src={plusIcon} /> Add another person</button>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="text-right">
                                <button type="button" onClick={handleSubmit} className="btn btn-primary waves-effect waves-light " disabled={state.isSubmit} >
                                    {
                                        state.isSubmit ?
                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                            : ''
                                    }
                                        Invite
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}