import React, { Component } from "react";
import { Modal } from "reactstrap";
import DataTable from "../../components/DataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import Api from "../../Utils/API";
import { Api as apiLink, ApiAdmin as apiLink2 } from '../../Utils/consts';
import {
    getCookie
} from '../../Utils/helper';
import {
    handleErrors
} from '../../Utils/handleError';
import moment from 'moment';

let projects = getCookie("projects");
let project = '';
if (projects) {
    project = (JSON.parse(projects));
}

let companys = getCookie("userDetails");
let company = '';
if (companys) {
    company = (JSON.parse(companys));
}

class Ticket extends Component {
    constructor() {
        super();
        this.state = {
            newTicket: false,
            ticketDetails: false,
            openModal: false,
            loading: false,
            customActiveTab: '1',
            list: [],
            filteredList: [],
            details: [],
            q: '',
            isSearch: false,
            spinner: false,
            project_id: project ? project[0].id : '',
            invoive_link: ''

        };
        this.viewInvoice = this.viewInvoice.bind(this);
    }

    componentDidMount() {
        this.getList();
    }

    getList() {
        if (company.company) {
            let params = {
                company_id: company.company.id,
                is_subscription: 0,
                is_transaction: 1
            }
            this.setState({ loading: true });
            Api.companyInvoices(params).then(result => {
                console.log(result.data)
                setTimeout(() => {
                    this.setState({ list: result.data, loading: false });
                }, 1000)
            }).catch(error => {
                handleErrors();
            });
        }
    }

    magnifyInvoice() {
        var html = `<h2>Print Data</h2>`;
        var printWin = window.open("", "printSpecial");
        printWin.document.open();
        printWin.document.write(html);
        printWin.document.close();
        printWin.print();
        printWin.close();
    }

    viewInvoice(e) {
        console.log(e);
        if(e.is_manual) {
            this.setState({loading: true, invoive_link: apiLink2 + 'invoice/' + e.filename, openModal: true, details: e });
        } else {
            this.setState({loading: true, invoive_link: apiLink + 'invoice/' + e.filename, openModal: true, details: e });
        }
        setTimeout( ()=>{
            this.setState({ loading: false});
        },2000);
    }

    render() {
        const dataTable = {
            columns: [
                {
                    label: "INVOICE ID",
                    field: "invoice_id",
                    width: 150
                },
                {
                    label: "PLAN",
                    field: "plan",
                    width: 150
                },
                {
                    label: "AMOUNT",
                    field: "amount"
                },
                {
                    label: "DATE & TIME",
                    field: "date",
                    width: 150
                },
                {
                    label: "ACTIONS",
                    field: "actions",
                    width: 150
                }

            ],
            rows: []
        };

        let list = this.state.list;
        if (this.state.isSearch) {
            list = this.state.filteredList;
        }

        let completedTask = 0;
        let currentTask = 0;
        //<button onClick={this.magnifyInvoice.bind(this)} className="btn btn-circle"><i className="fa fa-search"></i></button>
        for (let index = 0; index < list.length; index++) {
            console.log(list);
            dataTable.rows.push(
                {
                    invoice_id: list[index].receipt_number,
                    plan: list[index].plan,
                    amount: '$' + parseFloat(list[index].total/100).toFixed(2),
                    date: list[index].lastModified,
                    actions: <div>  <button invoice={list[index].filename} onClick={() => { this.viewInvoice(list[index]) }} className="btn btn-circle"><i className="fa fa-search"></i></button> </div>
                }
            );
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                {/* <LoadingIndicator loading={this.state.loading}/> */}
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title-box">
                                    <h2>Transactions</h2>
                                    <div className="circle">
                                        <span>{this.state.list.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className
                                    ="right-action-container">
                                    <div className="row-1">
                                        <div className="form-group has-search search-input text-right">
                                            <span className="fa fa-search form-control-feedback"></span>
                                            <input type="text" className="form-control" placeholder="Search" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 nopadding">
                                <DataTable
                                    data={dataTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.openModal}
                    toggle={() => { this.setState({ openModal: false }) }}
                    centered={true}
                    size="xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">INVOICE ID: {this.state.details.receipt_number}</h5>
                        <button
                            type="button"
                            onClick={() => { this.setState({ openModal: false }) }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {
                                this.state.spinner ?
                                   <div id="cover-spin"></div>
                                :''
                            }
                            <embed src={this.state.invoive_link} type="application/pdf" height="700px" width="100%" />
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Ticket;
