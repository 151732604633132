import React, { Component } from "react";
import DataTable from "../../components/DataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import Api from "../../Utils/API";
import { Api as apiLink } from '../../Utils/consts';
import {
    getCookie
} from '../../Utils/helper';
import {
    handleErrors
} from '../../Utils/handleError';
import moment from 'moment';

let projects = getCookie("projects");
let project = '';
if (projects) {
    project = (JSON.parse(projects));
}

let companys = getCookie("userDetails");
let company = '';
if (companys) {
    company = (JSON.parse(companys));
}

class Referrals extends Component {
    constructor() {
        super();
        this.state = {
            newTicket: false,
            ticketDetails: false,
            openModal: false,
            loading: false,
            customActiveTab: '1',
            list: [],
            filteredList: [],
            details: [],
            query: '',
            project_id: project ? project[0].id : '',
            invoive_link: '',
            searchTypingTimeout: 0
        };
        this.viewInvoice = this.viewInvoice.bind(this);
    }

    componentDidMount() {
        this.getList('');
    }

    getList(query) {
        if (company.company) {
            let params = {
                company_id: company.company.id,
                query
            }
            this.setState({ loading: true });
            Api.getCommissions(params).then(result => {
                console.log(result.data)
                setTimeout(() => {
                    this.setState({ list: result.data, loading: false });
                }, 1000)
            }).catch(error => {
                handleErrors();
            });
        }
    }

    viewInvoice(e) {
        console.log(e);
        this.setState({loading: true, invoive_link: apiLink + 'invoice/' + e.filename, openModal: true, details: e });
        setTimeout( ()=>{
            this.setState({ loading: false});
        },2000);
    }

    handleSearchChange = (e) => {
        if (this.state.searchTypingTimeout) {
            clearTimeout(this.state.searchTypingTimeout);
        }

        const value = e.target.value;
        const $this = this;

        this.setState({
            query: value,
            searchTypingTimeout: setTimeout(function () {
                $this.getList(value);
            }, 2000)
        });
    }

    render() {
        const dataTable = {
            columns: [
                {
                    label: "Company",
                    field: "company",
                    width: 150
                },
                {
                    label: "Payment",
                    field: "payment",
                    width: 150
                },
                {
                    label: "Commission",
                    field: "commission",
                    width: 150
                },
                {
                    label: "Commission Tier",
                    field: "commission_tier",
                    width: 150
                },
                {
                    label: "Date",
                    field: "date",
                    width: 150
                }
            ],
            rows: []
        };

        let list = this.state.list;

        for (let index = 0; index < list.length; index++) {
            console.log(list);

            dataTable.rows.push(
                {
                    company: list[index].cname,
                    payment: '$' + parseFloat(list[index].payment/100).toFixed(2),
                    commission: '$' + parseFloat(list[index].commission/100).toFixed(2),
                    commission_tier: list[index].tier_level == 1 ? '1st' : '2nd',
                    date: moment(list[index].date).format("DD-MM-YYYY")
                }
            );
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                {/* <LoadingIndicator loading={this.state.loading}/> */}
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title-box">
                                    <h2>Referrals</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className
                                    ="right-action-container">
                                    <div className="row-1">
                                        <div className="form-group has-search search-input text-right">
                                            <span className="fa fa-search form-control-feedback"></span>
                                            <input type="text" className="form-control" placeholder="Search" onChange={(e) => this.handleSearchChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 nopadding">
                                <DataTable
                                    data={dataTable}
                                    entries={25}
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Referrals;
