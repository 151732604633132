import React from "react";
import { Modal } from "reactstrap";

import conformDeleteIcon from "../../assets/images/conformDeleteIcon.png";

export default function ModalGeneral(props) {

   

    return (
        <React.Fragment>
            <Modal
                isOpen={props.open}
                toggle={props.hideModal}
                centered={true}
                className="modal-general"
            >
                <div className="modal-header">
                    <button
                        type="button"
                        onClick={props.hideModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <img  src={conformDeleteIcon} />
                    <h5 className="mt-4">Delete Confirmation</h5>
                    <p className="mt-4">Are you sure you want to delete this file?</p>
                    <p>You can’t undo this action.</p>
                    <button  onClick={props.open} type="button" className="btn btn-light waves-effect waves-light btn-rounded">Don’t Delete</button> &nbsp;
                    <button onClick={props.action} className="btn btn-primary" >Yes, Delete</button>
                </div>
            </Modal>
        </React.Fragment>
    );
}