
import React from "react";

export default function Loading(props) {
    if(!props.show){
        return (
            <React.Fragment>
             
            </React.Fragment>
        ) 
    }
    return (
        <React.Fragment>
            <div className="loading-div" >
                <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i> {props.title}
           </div>
        </React.Fragment>
    )
}