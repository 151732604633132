import React from "react";
import Swal from 'sweetalert2'
import { Modal } from "reactstrap";

export default function ApproveTicket(props) {

  return (
    <Modal 
      isOpen={props.open}
      toggle={()=>props.hideModal()}
      centered={true}
      size="md"
      style={{maxWidth: '435px', width: '100%'}}
      className="approve"
    >
      <div className="modal-header">
          <h5 className="modal-title mt-0">Approve Job Scope</h5>
          <button
              type="button"
              onClick={()=>props.hideModal()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div className="modal-body">
        <div>
          <div>
            Are you sure you want to approve Job Scope for project ‘On demand streaming’?
          </div>

        </div>
      </div>
      <div className="modal-footer">
          <div className="approve-button cancel" onClick={()=>props.hideModal()} >Cancel</div>
          <div className="approve-button approve" onClick={()=>props.onConfirm()} >{props.isLoading?<i className="bx bx-loader bx-spin font-size-20 align-middle "></i>:'✓ Approve'}</div>
      </div>
    </Modal>
  )
}