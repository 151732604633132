import React from 'react';
import Swal from 'sweetalert2';
import { Alert} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import queryString from 'query-string';

// import images
import profile from "../../assets/images/profile-img.png";
import loginBG from "../../assets/images/login-bg.jpg";
import emailIco from "../../assets/images/complete-account-email-icon.png";
import Helmet from "../../components/Seo";
import logo from "../../assets/images/jiffi-logo.png";
import iconMail from "../../assets/images/icon-mail.png";
import iconCall from "../../assets/images/icon-call.png";
import TextInput from "../../components/TextInput";

import {
    UniversalValidator,
    passwordValidator2,
    setCookie
} from '../../Utils/helper';
import Api from "../../Utils/API";


function CompleteAccount(props) {
    let params = queryString.parse(props.location.search);
    const [state, setState] = React.useState({
        username: '',
        usernamError: '',
        password: '',
        passwordError: '',
        password2: '',
        passwordError2: '',
        passwordType: 'password',
        passwordIcon: "fa fa-eye",
        passwordType2: 'password',
        passwordIcon2: "fa fa-eye",
        errorLogin: false,
        isSubmit: false,
        errorText: '',
        email: params.email,
        token: params.token
    });

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        //props.loginUser(values, props.history);
        //console.log(values);
    }

    function handleSubmit(event) {
        event.preventDefault();
       // const usernameError = UniversalValidator({ value: state.username, isValidate: true, isUrl: false, errorMsg: 'Please enter username.' });
        const passworError = UniversalValidator({ value: state.password, isValidate: true, isUrl: false, errorMsg: 'Please enter password.' });
        let passwordError2 = passwordValidator2([state.password, state.password2]);
        if (passworError || passwordError2) {
            setState({ ...state, passwordError: passworError, passwordError2 : passwordError2 });
        }else{
            setState({ ...state, isSubmit: !state.isSubmit });
            let params = {
                password: state.password,
                email: state.email,
                token: state.token
            } 
            console.log(params);
            Api.completeAccount(params).then(result =>{
                setCookie(["access_token", result.data.access_token]);
                setCookie(["projects", JSON.stringify(result.data.projects)]);
                setCookie(["userDetails", JSON.stringify(result.data.user)]);
                Swal.fire(
                        'Success!',
                        'Profile successfully completed.',
                        'success'
                    )
                setTimeout( ()=>{
                    window.location.href = "/ticket";
                },2000);
            }).catch(error =>{
                let errorText = `Something wen't wrong!`;
                if(error.response){
                    errorText = error.response.data;
                }
                setState({ ...state, errorLogin: true, isSubmit: false, errorText: errorText });
            });

        }
    }

    const tooglePassword = () => {
        if (state.passwordType === 'password') {
            setState({ ...state, passwordType: 'text', passwordIcon: "fa fa-eye-slash" });
        } else {
            setState({ ...state, passwordType: 'password', passwordIcon: "fa fa-eye" });
        }
    }

    const tooglePassword2 = () => {
        if (state.passwordType2 === 'password') {
            setState({ ...state, passwordType2: 'text', passwordIcon2: "fa fa-eye-slash" });
        } else {
            setState({ ...state, passwordType2: 'password', passwordIcon2: "fa fa-eye" });
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid container-login">
                <div className="row row-login" >
                    <div className="col-md-6 login-left ">
                        <div className="form-login" >
                            <div className="logo-div"> <img src={logo} /></div>
                            <div className="login-text">Complete Account</div>
                            <div className="complete-account-user-email"><img src={emailIco} /> {state.email ? state.email : 'sample@email.com'}</div>
                            {
                                state.errorLogin?
                                (
                                <Alert color="danger" role="alert">
                                    {state.errorText}
                                </Alert>
                                )
                                :''
                            }
                            <form onSubmit={handleSubmit}>
                                { /*
                                    <TextInput
                                    name="username"
                                    type="text"
                                    label="Username"
                                    placeholder="Enter Username"
                                    error={state.usernamError}
                                    value={state.username}
                                    handleChange={event => setState({ ...state, username: event.target.value, usernamError: '' })}
                                />*/
                                }
                                <TextInput
                                    name="password"
                                    type={state.passwordType}
                                    label="Password"
                                    placeholder="Enter password"
                                    error={state.passwordError}
                                    value={state.password}
                                    handleChange={event => setState({ ...state, password: event.target.value, passwordError: '' })}
                                    icon={state.passwordIcon}
                                    tooglePassword={tooglePassword}
                                    isPassword={true}
                                />

                                <TextInput
                                    name="password2"
                                    type={state.passwordType2}
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    error={state.passwordError2}
                                    value={state.password2}
                                    handleChange={event => setState({ ...state, password2: event.target.value, passwordError2: '' })}
                                    icon={state.passwordIcon2}
                                    tooglePassword={tooglePassword2}
                                    isPassword={true}
                                />
                               
                                <div className="mt-3">
                                   <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light btn-login" type="submit">
                                        Submit 
                                        {
                                            state.isSubmit?
                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                            :''
                                        }
                                    </button>
                                </div>

                                {/* LINKS */}
                                <div className="complete-account-home-link">
                                    <Link to="/login" className="back-home">{'<   Go to Home'}</Link>
                                </div>
                                <div className="text-center d-lg-none complete-account-links">
                                    <div>
                                        <img className="icon-footer" src={iconMail} /> support@jiffiwebhelp.com
                                     </div>
                                    <div>
                                        <img className="icon-footer-call" src={iconCall} /> 1300 247 516
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 login-right d-none d-md-block nopadding ">
                        <div className="bg-image"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(CompleteAccount);