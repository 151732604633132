import React from 'react';
import { saveAs } from 'file-saver';
import { Link } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import iconDL from "../assets/images/icon-dl.png";
import iconPDF from "../assets/images/icon-pdf.png";
import { stringLimit } from '../Utils/helper';
import { Api as ApiImage } from '../Utils/consts';



var fileSaver = require('file-saver');

export default function AttachmentFile(props) {
    const [state, setState] = React.useState(false);

    let list = props.data;
    let filename = list.filename;
    let typeData = filename.substr(filename.length - 3);;

    function formatBytes(bytes, decimals = 2) {
        bytes = bytes * 1000;
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const saveFile = (e) => {
        let link = e.target.getAttribute('link');
        let title = e.target.getAttribute('title');
        fileSaver.saveAs(
            link,
            title
        );
    }

    const zoomImage = () => {
        setState(true);
    }

    return (
        <React.Fragment>
            <div className="row-attachment attachment-list">
                <div className="column column-1">
                    {
                        typeData === 'pdf' ?
                            (<img src={iconPDF} />)
                            :
                            (<img  style={{cursor: 'pointer', objectFit: 'cover'}}  onClick={zoomImage} height="80" className="avatar-sm bg-light" alt={list.filename} src={list.path} />)
                    }
                    {
                        state ?
                            <Lightbox
                                mainSrc={list.path}
                                onCloseRequest={() => setState(false)}
                            // onMovePrevRequest={() =>
                            //     this.setState({
                            //         photoIndex: (photoIndex + images.length - 1) % images.length,
                            //     })
                            // }
                            // onMoveNextRequest={() =>
                            //     this.setState({
                            //         photoIndex: (photoIndex + 1) % images.length,
                            //     })
                            // }
                            />
                            : ''
                    }

                </div>
                <div className="column column-2">
                    <h5 className="font-size-14 mb-1" style={{cursor: 'pointer'}}  onClick={zoomImage} title={list.filename} >{stringLimit({ count: 12, title: list.filename })}</h5>
                    <small style={{cursor: 'pointer'}}  onClick={zoomImage}>Size : {formatBytes(list.filesize || '')}</small>
                </div>
                <div className="column column-3">
                    <img style={{ cursor: 'pointer' }} src={iconDL} title={list.filename} link={list.path} onClick={saveFile} />
                </div>
            </div>
        </React.Fragment>
    );
}


