import React, { Component } from "react";
import Swal from 'sweetalert2';
import { Link, withRouter } from "react-router-dom";
import Api from "../Utils/API";

class NoThankYou extends Component {
    constructor(props) {
        super(props);
        let token = '';
        if(props.location.pathname){
            var url = props.location.pathname.split('/',);
            if(url[2]){
               token = url[2];
            }
        }
        this.state = {
            token : token,
            loading : true,
        };
    };

    componentDidMount() {
        Api.noThankyou(this.state.token).then(result =>{   
            window.location.href= result.data;
        }).catch(error =>{
            this.setState({ loading : false });
            let err = 'Something went wrong!';

            if(error.response && error.response.data) {
                err = error.response.data;
            }

            Swal.fire(
                'Error!',
                err,
                'error'
            )
        });
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.loading ?
                       <div id="cover-spin"></div>
                    :''
                }
                
            </React.Fragment>
        );
    }
}

export default withRouter(NoThankYou);