import React, { Component } from "react";
import DataTable from "../../components/DataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import Api from "../../Utils/API";
import { Api as apiLink } from '../../Utils/consts';
import {
    getCookie,
    setCookie
} from '../../Utils/helper';
import {
    handleErrors
} from '../../Utils/handleError';
import moment from 'moment';
import imageIcon from "../../assets/images/ticket-notification.png";



class Settings extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            notificationSettings: { approvalNotification: true, generalNotification: true} // initialize
        };
        this.changeNotificationSetting = this.changeNotificationSetting.bind(this);
        this.updateSetting = this.updateSetting.bind(this);
        this.showTost = this.showTost.bind(this);
        this.closeToast = this.closeToast.bind(this);
    }

    componentDidMount() {
        //on Load
        let userSettings = getCookie("settings");
        let setting = { approvalNotification: true, generalNotification: true}; 
        this.setState({notificationSettings: setting});
        if (userSettings && userSettings !== undefined && userSettings !== 'undefined' && userSettings !== 'null') {
            setting = JSON.parse(userSettings)
            this.setState({notificationSettings: setting})
        }
    }

    changeNotificationSetting = (name) => {
        const notificationSettingsCopy = {...this.state.notificationSettings};
        notificationSettingsCopy[name] = !notificationSettingsCopy[name];
        this.setState({ notificationSettings: notificationSettingsCopy });
    }

    showTost = () => {
        // Get the snackbar DIV

        // Add the "show" class to DIV
        document.getElementById("snackbar").className = "show row notification-div";
        // After 3 seconds, remove the show class from DIV
        setTimeout(() => { this.closeToast() }, 3000);
    }

    closeToast = () =>{
        document.getElementById("snackbar").className = document.getElementById("snackbar").className.replace("show", "");
    }

    updateSetting = async () => {
        this.setState({loading: true});
        const res = await Api.updateSetting(JSON.stringify(this.state.notificationSettings))
        if(res){
            setCookie(["settings", res.data.settings]); //update user settings cookie
        }
        this.showTost();
        this.setState({loading: false});
    }




    render() {

       

        return (
            <React.Fragment>
                <div id="snackbar" onClick={this.closeToast} className="display-none" >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>Success!</h5>
                        <p>Setting saved!</p>
                    </div>
                </div>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                {/* <LoadingIndicator loading={this.state.loading}/> */}
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title-box">
                                    <h2>Settings</h2>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-24">
                            <div className="settings-tabs-container">
                                <div>
                                    <div className="tab active">Email Notifications</div>
                                    <div className="tab">Balance Alert</div>
                                    <div className="tab">Change Password</div>
                                </div>
                            </div>
                            <div className="flex-1 notification-settings-container">
                                <div className="flex flex-col px-32 pt-26 pb-17 gap-7">
                                    <div className="weight-500 text-18">Email Notifications</div>
                                    <div className="text-14">When your task requests move from phase to phase, you will get notified via email.</div>
                                </div>
                                <div className="flex flex-col notification-settings-body notifaction-setting-border">
                                    <div className="flex flex-row gap-12 flex-1 w-full">
                                        <div>
                                            <label className="custom-toggle custom-toggle-primary" >
                                                <input type="checkbox" checked={this.state.notificationSettings.generalNotification} onChange={()=>this.changeNotificationSetting('generalNotification')}/>
                                                <span className="custom-toggle-slider rounded-circle"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <div className="font-weight-bold">General Notifications</div>
                                            <div>Notify me when my request has is being investigated before commencement,  work has started, and when your work is being tested.</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-12 flex-1 w-full">
                                        <div>
                                            <label className="custom-toggle custom-toggle-primary">
                                                <input type="checkbox" checked={this.state.notificationSettings.approvalNotification} onChange={()=>this.changeNotificationSetting('approvalNotification')} />
                                                <span className="custom-toggle-slider rounded-circle"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <div className="font-weight-bold">For Approval Notifications</div>
                                            <div>Notify me when my request scope is ready to confirm, Ready to Approve, and Ready to Deploy phases.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-end p-24">
                                    <button onClick={this.updateSetting} className="setting-save-button waves-effect waves-light">
                                        {
                                            this.state.loading ?
                                                (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"> </i>)
                                                : ''
                                        }
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;
