import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const PagesStarter = (props) => {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />

                    </Container>
                </div>
            </React.Fragment>
              );
        }
            
export default PagesStarter;