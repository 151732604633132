import React from 'react';
import Swal from "sweetalert2";

export default function ConfirmSend(props) {  
    return (
        Swal.fire({
            title: 'Confirmation',
            text: `Are you sure you want to send a ${props.page}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3680ed',
            cancelButtonColor: '#ebe8e8',
            confirmButtonText: 'Yes, Please.',
            showCloseButton: true,
            customClass: {
                confirmButton: 'swal-custom-confirm',
                cancelButton: 'swal-custom-cancel',
                // container: 'swal-custom-container',
                // header: 'swal-custom-header',
                // title: 'swal-custom-title',
                // content: 'swal-custom-content',
                // icon: 'swal-custom-icon',
                // popup: 'swal-custom-popup'
            },
            cancelButtonText: "Cancel",
            reverseButtons: true
          }).then((result) => {
            return result;
        })
    );
}

