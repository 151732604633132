import Swal from 'sweetalert2'


export const handleErrors = data => {
    Swal.fire({
        title: 'Error!',
        text: 'Something went wrong!',
        icon: 'error',
        confirmButtonText: 'Close'
    });
};