// import React, { useState, useEffect } from "react";
// import LoadingBar from "react-top-loading-bar";



// export default function LoadingIndicator(props) {
//     const [progress, setProgress] = useState(0);
//     useEffect(() => {
//         var i = 0;
//         if (props.loading) {
//             clearInterval(progress);
//         } else {
//             const id = setInterval(() => {
//                 i++;
//                 setProgress(i);
//             }, 50);
//             setProgress(i);
//         }
//         console.log('timer', progress);
//         return () => clearInterval(progress);
//     }, [props.loading]);


//     return (
//         <div>
//             <LoadingBar
//                 color='#f15a23'
//                 progress={progress}
//                 onLoaderFinished={() => setProgress(0)}
//             />
//         </div>

//     );

// }

import React, { Component } from "react";

import LoadingBar from "react-top-loading-bar";




export default class LoadingIndicator extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            loadingBarProgress: 0,
            percentageLoading: 50,
        };
    }


    componentDidMount() {
        var i = 0;
        var counterBack = setInterval( () => {
            i++;
            if (i <= 99.9) {
                this.setState({ loadingBarProgress: i });
            } else {
                clearInterval(counterBack);
            }
           

        }, this.state.percentageLoading);

    }

    add = value => {
        this.setState({
            loadingBarProgress: this.state.loadingBarProgress + value
        });
    };

    complete = () => {
        this.setState({ loadingBarProgress: 100 });
    };

    onLoaderFinished = () => {
        this.setState({ loadingBarProgress: 0 });
    };

    render() {
        return (
            <div>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color="#f15a23"
                    onLoaderFinished={() => this.onLoaderFinished()}
                />
                {/* <button onClick={() => this.add(10)}>Add 10</button>
        <button onClick={() => this.add(30)}>Add 30</button>
        <button onClick={() => this.complete()}>Complete</button> */}
            </div>
        );
    }
}